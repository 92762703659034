export const fileToDataUrl = async (file: File | Blob | null | undefined): Promise<string | null> => {
  if (!file) {
    return null;
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      reader.onload = null;
      const result = reader.result;

      if (!result) {
        resolve(null);
        return;
      }

      resolve(reader.result + "");
    };

    reader.onerror = () => {
      reader.onerror = null;
      resolve(null);
    };
  });
};
