import { Base } from "../shared/base.model";

export enum ArticleAttachmentType {
  Image = "image",
}

export type ArticleAttachment = {
  _id: string;
  type: ArticleAttachmentType;
  key: string;
  gradient: string;
};

export enum ArticlePublishStatus {
  Draft = "Draft",
  Published = "Published",
}

export enum ArticleType {
  News = "news",
}

export enum ArticleBelongingType {
  Game = "game",
}

export interface Article extends Base {
  slug: string;

  title: string;
  shortDescription: string;
  readTime: number;

  rawHtml: string;

  type: ArticleType;
  belongsTo: {
    type: ArticleBelongingType;
    id: string;
  };

  cover: {
    _id: string;
    key: string;
    gradient: string;
  };

  attachments: ArticleAttachment[];

  publishStatus: ArticlePublishStatus;
}
