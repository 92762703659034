import { Box, Center, Fade, Spinner } from "@chakra-ui/react";
import React, { PropsWithChildren } from "react";

type Props = {
  noData: boolean;
  initialLoading: boolean;
  loading: boolean;
};

export const LoaderWrapper = (props: PropsWithChildren<Props>) => {
  if (props.noData) {
    return <Box textAlign="center">No Data</Box>;
  }

  if (props.loading && props.initialLoading) {
    return (
      <Center mt={2}>
        <Spinner />
      </Center>
    );
  }

  return (
    <Box
      transition={"opacity 300ms"}
      opacity={!props.initialLoading && props.loading ? 0.5 : 1}
      pointerEvents={!props.initialLoading && props.loading ? "none" : "initial"}
    >
      <Fade in={true}>{props.children}</Fade>
    </Box>
  );
};