import { Button, HStack, Modal, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { DeleteIcon, WarningIcon } from "@chakra-ui/icons";

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;

  actionText: string;
  actionLoading: boolean;
  onAction: () => void;

  type: "remove" | "action";
};

export const GenericConfirmModal = (props: Props) => {
  const colorSchemeMapper = {
    remove: "red",
    action: "green",
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems={"center"} columnGap={3}>
          <WarningIcon color="yellow.400" />
          {props.title}
        </ModalHeader>
        <ModalFooter>
          <HStack spacing={2}>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button
              variant={"outline"}
              colorScheme={colorSchemeMapper[props.type]}
              isLoading={props.actionLoading}
              onClick={props.onAction}
              {...(props.type === "remove" ? { leftIcon: <DeleteIcon /> } : {})}
            >
              {props.actionText}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
