import { Box, Button, Divider, Stack, useBoolean, useDisclosure, useToast } from "@chakra-ui/react";
import React from "react";
import { storeService } from "../../api/stores/store.service";
import { StoreCardItem } from "./components/StoreCardItem";
import { SmallAddIcon } from "@chakra-ui/icons";
import { useStores } from "../../hooks/useStores";
import { LoaderWrapper } from "../../components/ListLoaderWrapper/LoaderWrapper";
import { GenericFormModal } from "../../components/GenericFormModal/GenericFormModal";
import * as yup from "yup";
import { StoreName } from "../../api/stores/store-names.enum";

export const StoresListPage = () => {
  const toast = useToast();
  const storesData = useStores();
  const createStoreModalDisclosure = useDisclosure();

  const [createStoreLoading, updateCreteStoreLoading] = useBoolean(false);

  const createStore = async ({ name, url }: Record<string, string>) => {
    const isStoreName = (name: string): name is StoreName => {
      if (Object.values(StoreName).includes(name as unknown as StoreName)) {
        return true;
      }

      return false;
    };

    if (!isStoreName(name)) {
      toast({
        title: "Store name is not in supported store list",
        status: "error",
      });
      return;
    }

    updateCreteStoreLoading.on();

    try {
      await storeService.create({ name, url });
      storesData.refetch();
      updateCreteStoreLoading.off();
      createStoreModalDisclosure.onClose();
      toast({
        title: "Store was successfully created",
        status: "success",
      });
    } catch (err) {
      toast({
        title: "Store was not created",
        status: "error",
      });
      console.log(err);
    }
  };

  return (
    <Box>
      <Button onClick={createStoreModalDisclosure.onOpen} leftIcon={<SmallAddIcon />}>
        Add Store
      </Button>
      <Divider orientation="horizontal" my="6" />
      <LoaderWrapper
        noData={storesData.hasBeenLoaded && !storesData.list.length}
        initialLoading={storesData.loading && !storesData.hasBeenLoaded}
        loading={storesData.loading}
      >
        <Stack spacing="4">
          {storesData.list.map((store) => (
            <StoreCardItem
              key={store._id}
              store={store}
              onRemoveSuccessfully={storesData.refetch}
              onRenameSuccessfully={storesData.refetch}
            />
          ))}
        </Stack>
      </LoaderWrapper>
      <GenericFormModal
        title={"Create Store"}
        isOpen={createStoreModalDisclosure.isOpen}
        onClose={createStoreModalDisclosure.onClose}
        actionLoading={createStoreLoading}
        onSubmit={createStore}
        controls={[
          {
            name: "name",
            label: "Name",
          },
          {
            name: "url",
            label: "Url",
          },
        ]}
        controlsValidation={yup.object().shape({
          name: yup.string().required(),
          url: yup.string().required(),
        })}
      />
    </Box>
  );
};
