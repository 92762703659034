import { LoaderWrapper } from "../../components/ListLoaderWrapper/LoaderWrapper";
import { useEffect, useState } from "react";
import { Game } from "../../api/games/game.model";
import { Box, Button, IconButton, useBoolean, useDisclosure, useToast } from "@chakra-ui/react";
import { gameService } from "../../api/games/game.service";
import { GenericFormModalV2 } from "../../components/GenericFormModalV2/GenericFormModalV2";
import { useGenericFormV2 } from "../../components/GenericFormV2/use-generic-form-v2";
import { GenericFormV2ControlType } from "../../components/GenericFormV2/types";
import { PlusSquareIcon, SearchIcon } from "@chakra-ui/icons";
import { SortingTable } from "./components/SortingTable/SortingTable";
import { GamesSearchWidgetModal } from "../../widgets/GamesSearchWidget/components/GamesSearchWidgetModal/GamesSearchWidgetModal";

export const AppConfigCrossplayGamesStaticSortPriority = () => {
  const toast = useToast();

  const [games, setGames] = useState<Game[]>([]);
  const [gamesLoading, setGamesLoading] = useState<boolean>(true);
  const [gamesHasBeenLoaded, setGamesHasBeenLoaded] = useState<boolean>(false);

  const [staticSortPriorityLoading, setStaticSortPriorityLoading] = useState<boolean>(false);

  const [selectedGame, setSelectedGame] = useState<Game | null>(null);
  const [showSearchModal, setShowSearchModal] = useBoolean();

  const staticSortPriority = useDisclosure();

  const refetch = async () => {
    setGamesLoading(true);

    try {
      const { data: entities } = await gameService.retrieveOnlyWithCrossplayStaticSortPriority();

      setGames(entities);
      setGamesHasBeenLoaded(true);
    } catch (err) {
      toast({ title: `Games was not loaded due to error`, status: "error" });
    }

    setGamesLoading(false);
  };

  useEffect(() => {
    refetch();
  }, []);

  const onOpenSearchGameModal = () => {
    setShowSearchModal.on();
  };

  const staticSortPriorityForm = useGenericFormV2({
    initialValues: {
      gameId: "",
      sortPriority: "",
    },
    controls: [
      {
        type: GenericFormV2ControlType.Text,
        name: "gameId",
        label: "Game ID",
        rightElement: {
          render: (props) => (
            <IconButton
              variant="outline"
              aria-label="search-game-button"
              icon={<SearchIcon />}
              isDisabled={false}
              onClick={onOpenSearchGameModal}
              {...props}
            />
          ),
          name: "searchGame",
          props: {},
        },
      },
      {
        type: GenericFormV2ControlType.Text,
        name: "sortPriority",
        label: "Sort Priority",
      },
    ],
    onSubmit: ({ sortPriority, gameId }) => {
      const isEdit = selectedGame !== null;

      if (!isEdit) {
        if (games.find((game) => game._id === gameId)) {
          toast({
            title: "Game already have an sort priority. If you want to change it, edit instead",
            status: "error",
          });
          return;
        }
      }

      applySortPriorityToGame(gameId, sortPriority);
    },
  });

  const applySortPriorityToGame = async (gameId: string, sortPriority: number | string) => {
    if (typeof sortPriority === "string") {
      if (isNaN(+sortPriority.trim())) {
        return;
      }

      sortPriority = +sortPriority.trim();
    }

    setStaticSortPriorityLoading(true);

    try {
      await gameService.applyCrossplayStaticSortPriority(gameId, sortPriority);

      toast({
        title: "Sort priority was updated successfully",
        status: "success",
      });

      await refetch();

      staticSortPriority.onClose();
    } catch (err) {
      toast({
        title: "Sort priority was not updated successfully",
        status: "error",
      });
    }

    setStaticSortPriorityLoading(false);
  };

  useEffect(() => {
    if (staticSortPriority.isOpen) {
      return;
    }

    staticSortPriorityForm.enableField("gameId");
    staticSortPriorityForm.formik.resetForm({});
    setSelectedGame(null);
  }, [staticSortPriority.isOpen]);

  const onAddClick = () => {
    const defaultValue = (games[0]?.appSpecificConfig.crossplay.staticSortPriority || 0) + 1;

    staticSortPriorityForm.setFieldValue("sortPriority", defaultValue);
    staticSortPriorityForm.updateExternalControlProps("searchGame", { isDisabled: false });
    staticSortPriority.onOpen();
  };

  const onUpdateClick = (game: Game) => {
    setSelectedGame(game);
    staticSortPriorityForm.setFieldValue("gameId", game._id);
    staticSortPriorityForm.setFieldValue("sortPriority", game.appSpecificConfig.crossplay.staticSortPriority);
    staticSortPriorityForm.disableField("gameId");
    staticSortPriorityForm.updateExternalControlProps("searchGame", { isDisabled: true });

    staticSortPriority.onOpen();
  };

  const onClickGameCardClick = (gameID: string) => {
    staticSortPriorityForm.setFieldValue("gameId", gameID);
  };

  return (
    <Box>
      <Button leftIcon={<PlusSquareIcon />} colorScheme="teal" variant="outline" onClick={onAddClick} size="sm">
        Apply Sort Priority For Game
      </Button>
      <GenericFormModalV2
        title="Apply Sort Priority"
        isOpen={staticSortPriority.isOpen}
        onClose={staticSortPriority.onClose}
        actionLoading={staticSortPriorityLoading}
        onAction={staticSortPriorityForm.formik.submitForm}
        controls={staticSortPriorityForm.controls}
        formik={staticSortPriorityForm.formik}
      >
        <GamesSearchWidgetModal
          onClickGameCard={onClickGameCardClick}
          isOpen={showSearchModal}
          onClose={setShowSearchModal.off}
        />
      </GenericFormModalV2>
      <LoaderWrapper
        noData={gamesHasBeenLoaded && !games.length}
        initialLoading={gamesLoading && !gamesHasBeenLoaded}
        loading={gamesLoading}
      >
        <Box mt={5}>
          <SortingTable games={games} applySortPriorityToGame={applySortPriorityToGame} onUpdateClick={onUpdateClick} />
        </Box>
      </LoaderWrapper>
    </Box>
  );
};
