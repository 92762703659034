import axios, { AxiosResponse } from "axios";
import { endpoints } from "./endpoints";
import { Store } from "./store.model";

class StoreService {
  create(data: Pick<Store, "name" | "url">): Promise<AxiosResponse<Store>> {
    return axios.post(endpoints.create.insert(), {
      name: data.name,
      url: data.url,
    });
  }

  retrieveAll(): Promise<AxiosResponse<Store[]>> {
    return axios.get(endpoints.retrieveAll.insert());
  }

  update(
    id: string,
    data: Pick<Store, "name" | "url">
  ): Promise<AxiosResponse<Store>> {
    return axios.put(endpoints.update.insert({ storeId: id }), {
      name: data.name,
      url: data.url,
    });
  }

  remove(id: string): Promise<AxiosResponse<void>> {
    return axios.delete(endpoints.remove.insert({ storeId: id }));
  }
}
export const storeService = new StoreService();
