import { Game } from "../../../api/games/game.model";
import { useFormik } from "formik";
import { gameService } from "../../../api/games/game.service";
import {
  Badge,
  Box,
  Button,
  CloseButton,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Textarea,
  useBoolean,
  useToast,
  VStack,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { defaultTo } from "ramda";
import { useDropzone } from "react-dropzone";
import { uploaderService } from "../../../api/uploader/uploader.service";
import { GameSeoOgImage } from "./GameSeoOgImage";
import * as htmlToImage from "html-to-image";

const s3url = process.env.REACT_APP_ASSETS_URL;

type Props = {
  game: Game;
  onSaveSuccessfully: () => Promise<void>;
  updateMemoryGame: (updateFn: (prev: Game) => Game) => any;
};

export const GameSeo = (props: Props) => {
  const toast = useToast();
  const [loading, updateLoading] = useBoolean(false);

  const [imageFile, setImageFile] = useState(null);
  const [imageUrl, setImageUrl] = useState<string | null>(
    props.game.seo.crossplay.image ? `${s3url}/${props.game.seo.crossplay.image}` : null
  );

  const [manualKeywords, setManualKeywords] = useState<string[]>(
    props.game.seo.crossplay.keywords.manual.split(",").filter((elem) => elem.length)
  );

  const boxCover = useRef<HTMLDivElement>(null);

  const [imageYPosition, setImageYPosition] = useState<number>(0);

  useEffect(() => {
    setImageUrl(`${s3url}/${props.game.seo.crossplay.image}`);
    setImageFile(null);
  }, [props.game.seo.crossplay.image]);

  const onDrop = async (acceptedFiles: any) => {
    setImageFile(acceptedFiles[0]);
    setImageUrl(URL.createObjectURL(acceptedFiles[0]));
  };

  const uploadImage = async (): Promise<string> => {
    if (!boxCover.current) {
      throw new Error("No element");
    }

    const generatedImgBlob = await htmlToImage.toBlob(boxCover.current.children[0] as any, { type: "image/jpg" });

    if (!generatedImgBlob) {
      throw new Error("");
    }

    const formData = new FormData();
    // Last argument is not a file name, it will be the extension
    formData.append("file", generatedImgBlob, "jpg");

    const { data } = await uploaderService.upload(formData);

    return data.fileName;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/webp": [".webp"],
    },
    onDrop: onDrop,
  });

  const manualKeywordFormik = useFormik({
    initialValues: {
      keyword: "",
    },
    onSubmit: ({ keyword }, formikHelpers) => {
      if (!keyword.trim().length) {
        return;
      }

      const alreadyExist = manualKeywords.includes(keyword);

      if (alreadyExist) {
        return;
      }

      setManualKeywords((prev) => [...prev, keyword]);
      formikHelpers.resetForm();
    },
  });

  const removeManualKeyword = (keyword: string) => {
    setManualKeywords((prev) => prev.filter((value) => value !== keyword));
  };

  const formik = useFormik({
    initialValues: {
      name: defaultTo("", props.game.seo.crossplay.name),
      description: defaultTo("", props.game.seo.crossplay.description),
    },
    onSubmit: async ({ name, description }) => {
      updateLoading.on();

      try {
        const fileName = imageFile ? await uploadImage() : props.game.seo.crossplay.image;

        await gameService.updateSeoCrossplay(props.game._id, {
          name,
          description,
          manualKeywords: manualKeywords.join(","),
          image: fileName,
        });

        setImageUrl(`${s3url}/${fileName}`);
        setImageFile(null);

        toast({
          title: "SEO was successfully updated",
          status: "success",
        });

        props
          .onSaveSuccessfully()
          .catch(() =>
            toast({
              title: "Could not refetch Game, please reload a page",
              status: "warning",
            })
          )
          .then(() => updateLoading.off());
      } catch (err) {
        toast({
          title: "SEO was not updated",
          status: "error",
        });
        console.log(err);
      } finally {
        setImageFile(null);
        setImageYPosition(0);
      }
    },
  });

  useEffect(() => {
    formik.resetForm({
      values: {
        name: props.game.seo.crossplay.name,
        description: props.game.seo.crossplay.description,
      },
    });
  }, [props.game.seo.crossplay.name, props.game.seo.crossplay.description]);

  useEffect(() => {
    props.updateMemoryGame((prev) => ({
      ...prev,
      seo: {
        ...prev.seo,
        crossplay: {
          ...prev.seo.crossplay,
          name: formik.values.name,
          description: formik.values.description,
          image: imageUrl,
          keywords: {
            ...prev.seo.crossplay.keywords,
            manual: manualKeywords.join(","),
          },
        },
      },
    }));
  }, [imageUrl, formik.values.name, formik.values.description]);

  return (
    <Box>
      <VStack spacing={2}>
        <FormControl>
          <FormLabel>Name based keywords</FormLabel>
          <Flex wrap={"wrap"} rowGap={2} columnGap={2}>
            {props.game.seo.crossplay.keywords.autoGenerated.nameBased.split(",").map((keyword, index) => (
              <Badge key={index} variant="outline">
                {keyword}
              </Badge>
            ))}
          </Flex>
        </FormControl>
        <FormControl>
          <FormLabel>Cross based keywords</FormLabel>
          <Flex wrap={"wrap"} rowGap={2} columnGap={2}>
            {props.game.seo.crossplay.keywords.autoGenerated.crossBased.split(",").map((keyword, index) => (
              <Badge key={index} variant="outline">
                {keyword}
              </Badge>
            ))}
          </Flex>
        </FormControl>
        <FormControl>
          <FormLabel>Manual keywords</FormLabel>
          <HStack spacing={2}>
            <Input
              name={"keyword"}
              onKeyUp={(e) => {
                if (e.key === "Enter") {
                  manualKeywordFormik.submitForm();
                }
              }}
              onChange={manualKeywordFormik.handleChange}
              value={manualKeywordFormik.values.keyword}
            />
            <Button onClick={manualKeywordFormik.submitForm}>Add</Button>
          </HStack>
          <Flex mt={2} wrap={"wrap"} rowGap={2} columnGap={2}>
            {manualKeywords.map((keyword) => (
              <Badge key={keyword} variant="outline" colorScheme={"green"} padding={2}>
                <HStack>
                  <Box>{keyword}</Box>
                  <CloseButton onClick={() => removeManualKeyword(keyword)} size="sm" />
                </HStack>
              </Badge>
            ))}
          </Flex>
        </FormControl>
        <FormControl>
          <FormLabel>SEO Name</FormLabel>
          <Input name={"name"} onChange={formik.handleChange} value={formik.values.name} disabled={true} />
        </FormControl>
        <FormControl>
          <FormLabel>SEO Description</FormLabel>
          <Textarea
            name={"description"}
            onChange={formik.handleChange}
            value={formik.values.description}
            disabled={true}
            rows={5}
          />
        </FormControl>
        <Box maxWidth={"600px"} alignSelf={"flex-start"}>
          <FormControl>
            <FormLabel>Applied OG Image</FormLabel>
            <Box
              w="100%"
              __css={{
                "> div": {
                  padding: "40px",
                  marginBottom: 2,
                  textAlign: "center",
                  borderRadius: 10,
                  border: "1px",
                  borderColor: "gray.200",
                  transition: "background-color 0.3s ease-in-out",
                  cursor: "pointer",
                },
                "> div:hover": {
                  backgroundColor: "blue.100",
                },
              }}
            >
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                  <p>Drop the files here ...</p>
                ) : (
                  <p>Drag 'n' drop some files here, or click to select files</p>
                )}
              </div>
            </Box>
            {imageUrl && <Image src={imageUrl} />}
          </FormControl>
        </Box>
      </VStack>
      <Flex justifyContent={"flex-end"} mt={2}>
        <Button variant={"outline"} colorScheme={"green"} onClick={formik.submitForm} isLoading={loading}>
          Save
        </Button>
      </Flex>
      <Divider my={6} />
      {imageFile && (
        <Box>
          <Box>
            <Slider
              aria-label="slider-ex-1"
              min={-200}
              max={200}
              defaultValue={0}
              onChange={(val) => {
                setImageYPosition(val);
              }}
            >
              <SliderMark value={imageYPosition} textAlign="center" bg="blue.500" color="white" mt="-10" ml="-5" w="12">
                {imageYPosition}px
              </SliderMark>
              <SliderTrack>
                <SliderFilledTrack />
              </SliderTrack>
              <SliderThumb boxSize={7} />
            </Slider>
          </Box>
          <Box position={"relative"} transform={"scale(0.5) translateX(-50%)"} mb={2}>
            <GameSeoOgImage
              seo={{ image: imageUrl, name: formik.values.name, description: formik.values.description }}
              imageYPosition={imageYPosition}
            />
          </Box>
        </Box>
      )}
      <Box position={"absolute"} ref={boxCover} top={"-100%"} left={"-100%"} zIndex={-1}>
        {imageUrl && (
          <GameSeoOgImage
            seo={{ image: imageUrl, name: formik.values.name, description: formik.values.description }}
            imageYPosition={imageYPosition}
          />
        )}
      </Box>
    </Box>
  );
};
