import { Box, ChakraProps } from "@chakra-ui/react";

type Props = {
  icon: any;
  isActive: boolean;
  isDisabled: boolean;

  id: string;

  onClick: (id: string) => void;
};

const getBoxShadow = (color: string) => `0px 0px 0px 3px ${color}`;

const getStateBasedCSS = (isActive: boolean, isDisabled: boolean): ChakraProps => {
  if (isDisabled) {
    return {
      opacity: 0.3,
    };
  }

  if (isActive) {
    return {
      boxShadow: getBoxShadow("#6fc276"),
      opacity: 1,
    };
  }

  return {
    opacity: 0.9,
    _hover: {
      boxShadow: getBoxShadow("#cccccc"),
    }
  };
};

export const GameBuyLinkCreateModalIconCheckBox = (props: Props) => {
  return (
    <Box
      flexGrow={1}
      onClick={() => !props.isDisabled && props.onClick(props.id)}
      display={"inline-flex"}
      boxSizing={"content-box"}
      padding={2}
      borderRadius={6}
      flexShrink={0}
      background="linear-gradient(270deg,#373b44,#4286f4)"
      alignItems={"center"}
      justifyContent={"center"}
      fontSize={0}
      cursor={"pointer"}
      transition={"box-shadow 300ms, opacity 300ms"}
      {...getStateBasedCSS(props.isActive, props.isDisabled)}
    >
      {props.icon}
    </Box>
  );
};
