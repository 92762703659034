import { LoaderWrapper } from "../../components/ListLoaderWrapper/LoaderWrapper";
import {
  Box,
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as yup from "yup";
import React, { useState } from "react";
import { AuthService } from "../../api/auth/auth.service";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../themes/icons";

const schema = yup.object().shape({
  name: yup.string().required(),
  password: yup.string().required(),
});

export const LoginPage = () => {
  const toast = useToast();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const loginForm = useFormik({
    initialValues: { name: "", password: "" },
    validationSchema: schema,
    onSubmit: async (values) => {
      setLoading(true);

      try {
        const { data } = await AuthService.login({ name: values.name, password: values.password });
        localStorage.setItem("token", data);
        navigate("/");
      } catch (e) {
        toast({
          title: (e as Error).message,
          status: "error",
        });
        console.log(e);
      }

      setLoading(false);
    },
  });

  return (
    <LoaderWrapper noData={false} initialLoading={true} loading={false}>
      <Container maxW="lg" py={{ base: "12", md: "24" }} px={{ base: "0", sm: "8" }}>
        <Stack spacing="8">
          <Stack spacing="6">
            <Flex
              justifyContent="center"
              borderRadius="xl"
              borderBottomRadius={0}
              _light={{ backgroundColor: "blue.500" }}
              _dark={{ backgroundColor: "blue.200" }}
            >
              <Logo w={250} h={45} />
            </Flex>
          </Stack>
          <Box
            py={{ base: "0", sm: "8" }}
            px={{ base: "4", sm: "10" }}
            bg={{ base: "transparent", sm: "bg.surface" }}
            boxShadow={{ base: "none", sm: "lg" }}
            borderRadius="xl"
            borderTopRadius={0}
            style={{ marginTop: 0 }}
            _dark={{ backgroundColor: "gray.700" }}
          >
            <Stack spacing="6">
              <Heading
                size={{ base: "xs", md: "lg" }}
                fontWeight="medium"
                textAlign="center"
                _light={{ color: "blackAlpha.700" }}
              >
                Log in to your account
              </Heading>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  loginForm.handleSubmit();
                }}
              >
                <Stack spacing="5">
                  <FormControl>
                    <FormLabel>Name</FormLabel>
                    <Input type="text" name="name" onChange={loginForm.handleChange} value={loginForm.values.name} />
                    <FormErrorMessage>{loginForm.errors["name"]}</FormErrorMessage>
                  </FormControl>
                  <FormControl>
                    <FormLabel>Password</FormLabel>
                    <Input
                      type="password"
                      name="password"
                      onChange={loginForm.handleChange}
                      value={loginForm.values.password}
                    />
                    <FormErrorMessage>{loginForm.errors["password"]}</FormErrorMessage>
                  </FormControl>
                </Stack>
                <Stack spacing="6">
                  <Button type="submit" mt={2} colorScheme="blue" isLoading={loading}>
                    Sign In
                  </Button>
                </Stack>
              </form>
            </Stack>
          </Box>
        </Stack>
      </Container>
    </LoaderWrapper>
  );
};
