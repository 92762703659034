import { useEffect, useState } from "react";
import { storeService } from "../api/stores/store.service";
import { Store } from "../api/stores/store.model";

export const useStores = () => {
  const [list, setList] = useState<Store[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasBeenLoaded, setHasBeenLoaded] = useState<boolean>(false);

  const load = async () => {
    setLoading(true);

    try {
      const { data } = await storeService.retrieveAll();
      setList(data);
      setLoading(false);
      setHasBeenLoaded(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    load().then().catch();
  }, []);

  const refetch = () => {
    load();
  };

  return {
    list,
    refetch,

    loading,
    hasBeenLoaded,
  };
};
