import { useEffect, useState } from "react";
import { Game } from "../../../api/games/game.model";
import { useParams } from "react-router-dom";
import { gameService } from "../../../api/games/game.service";

export const useGame = () => {
  const { gameId } = useParams();

  const [entity, setEntity] = useState<Game>();
  const [loading, setLoading] = useState<boolean>(false);
  const [hasBeenLoaded, setHasBeenLoaded] = useState<boolean>(false);

  const load = async () => {
    if (!gameId) {
      return;
    }

    setLoading(true);

    try {
      const { data } = await gameService.retrieveById(gameId);
      setEntity(data);
      setLoading(false);
      setHasBeenLoaded(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    load().then().catch();
  }, []);

  const refetch = () => {
    return load();
  };

  return {
    entity,
    refetch,

    loading,
    hasBeenLoaded,
  };
};
