import * as React from "react";
import { ChakraProvider, Container, Grid, GridItem, HStack, Tooltip, IconButton } from "@chakra-ui/react";
import { CategoryMenu, Header } from "./components";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { StoresListPage, PlatformsListPage, GamesListPage, GamePage, LoginPage } from "./pages";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import { GameUrlTabNames } from "./pages/GamePage/game-url-tab-names";
import { GrLogout } from "react-icons/all";
import { AppConfigCrossplayGamesStaticSortPriority } from "./pages/AppConfigCrossplayGamesSortOrder/AppConfigCrossplayGamesStaticSortPriority";
import { GenresListPage } from "./pages/GenresListPage/GenresListPage";
import { PlayModesListPage } from "./pages/PlayModesListPage/PlayModesListPage";
import { SocialsListPage } from "./pages/SocialsListPage/SocialsListPage";
import { ArticlesPage } from "./pages/ArticlesPage/ArticlesPage";
import { ArticleCreatePage } from "./pages/ArticleCreatePage/ArticleCreatePage";
import { ArticleManagePage } from "./pages/ArticleManagePage/ArticleManagePage";

export const App = () => {
  const navigate = useNavigate();

  return (
    <ChakraProvider toastOptions={{ defaultOptions: { position: "top-right", isClosable: true } }}>
      <Routes>
        <Route path="/auth/login" element={<LoginPage />} />
        <Route
          path="/*"
          element={
            <Grid templateColumns="260px 1fr" templateRows="1fr">
              <GridItem rowSpan={2}>
                <CategoryMenu />
              </GridItem>
              <GridItem px="6" rowSpan={1} pos="sticky" zIndex={1} top="0">
                <Container maxW="container.xl" display="flex" pos="relative" alignItems="center">
                  <Header />
                  <HStack>
                    <ColorModeSwitcher pos="absolute" right={20} />
                    <Tooltip label="Logout" hasArrow>
                      <IconButton
                        pos="absolute"
                        right={7}
                        onClick={() => {
                          localStorage.removeItem("token");
                          navigate("/");
                        }}
                        aria-label="Logout"
                        icon={<GrLogout />}
                        _dark={{ path: { stroke: "white" } }}
                      />
                    </Tooltip>
                  </HStack>
                </Container>
              </GridItem>
              <GridItem p="6" rowSpan={1}>
                <Container maxW={"container.xl"}>
                  <Routes>
                    <Route path="/" element={<Navigate to="/games" />} />
                    <Route path="/games" element={<GamesListPage />} />
                    <Route path="/platforms" element={<PlatformsListPage />} />
                    <Route path="/stores" element={<StoresListPage />} />
                    <Route path="/genres" element={<GenresListPage />} />
                    <Route path="/socials" element={<SocialsListPage />} />
                    <Route path="/articles" element={<ArticlesPage />} />
                    <Route path="/articles/:articleId" element={<ArticleManagePage />} />
                    <Route path="/create-article" element={<ArticleCreatePage />} />
                    <Route path="/play-modes" element={<PlayModesListPage />} />
                    <Route path="/games/:gameId" element={<Navigate to={GameUrlTabNames.BasicInfo} />} />
                    <Route path="/games/:gameId/:tab" element={<GamePage />} />
                    <Route
                      path="/app-config/crossplay/games-sort-order"
                      element={<AppConfigCrossplayGamesStaticSortPriority />}
                    />
                  </Routes>
                </Container>
              </GridItem>
            </Grid>
          }
        />
      </Routes>
    </ChakraProvider>
  );
};
