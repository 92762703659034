export const util_arr_updateObj = <T extends object, K extends keyof T>(
  arr: T[],
  key: K,
  value: T[K],
  updater: (element: T) => T
) => {
  return arr.map((obj) => {
    if (obj[key] === value) {
      return updater(obj);
    }

    return obj;
  });
};
