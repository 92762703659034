export const extractPaginationFromUrl = (queryString: string): { take: number; skip: number } | null => {
  const searchParams = new URLSearchParams(queryString);

  const take = searchParams.get("take");
  const skip = searchParams.get("skip");

  if (take === null || skip === null) {
    return null;
  }

  if (isNaN(+take) || isNaN(+skip)) {
    return null;
  }

  return {
    take: +take,
    skip: +skip,
  };
};
