import { GameFilter } from "../types/game-filter";

export const createQueryForGameFilter = (gameFilter: GameFilter): string => {
  const searchParams = new URLSearchParams();

  for (const [key, value] of Object.entries(gameFilter)) {
    searchParams.set(key, value);
  }

  return searchParams.toString();
};
