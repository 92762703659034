export const util_arr_changeElemPosition = <T extends object>(
  arr: T[],
  prevPosition: number,
  nextPosition: number
): T[] => {
  const resultedArr: T[] = [];

  const elementToChange: T = arr[prevPosition];

  if (!elementToChange) {
    return [...arr];
  }

  // Know direction we know where element should be, before or after
  const direction = nextPosition > prevPosition ? 1 : -1;

  arr.forEach((arrElem, index, arr) => {
    if (arrElem === elementToChange) {
      return;
    }

    if (index !== nextPosition) {
      resultedArr.push(arrElem);
    }

    if (index === nextPosition) {
      if (direction === 1) {
        resultedArr.push(arrElem, elementToChange);
      } else {
        resultedArr.push(elementToChange, arrElem);
      }
    }
  });

  return resultedArr;
};
