import {
  Badge,
  Box,
  Button,
  Divider,
  HStack,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
} from "@chakra-ui/react";
import { Game } from "../../../../api/games/game.model";
import { useArticles } from "../../../../hooks/useArticles";
import { LoaderWrapper } from "../../../../components/ListLoaderWrapper/LoaderWrapper";
import { Pagination } from "../../../../components/Pagination/Pagination";
import React from "react";
import { useNavigate } from "react-router-dom";
import { EditIcon } from "@chakra-ui/icons";

type Props = {
  game: Game;
  refetchGame: () => Promise<void>;
  updateMemoryGame: (updateFn: (prev: Game) => Game) => any;
};

export const GameArticlesTab = (props: Props) => {
  const navigate = useNavigate();
  const articlesData = useArticles({
    belongsTo: props.game._id,
  });

  const handlePaginationPageChange = (skip: number) => {
    articlesData.load({ skip });
  };

  return (
    <Box>
      <Button onClick={() => navigate(`/create-article?gameId=${props.game._id}`)}>Create Article</Button>
      <Divider orientation="horizontal" my="6" />
      <LoaderWrapper
        noData={articlesData.hasBeenLoaded && !articlesData.data.entities.length}
        initialLoading={articlesData.loading && !articlesData.hasBeenLoaded}
        loading={articlesData.loading}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <TableContainer>
            <Table size="sm">
              <Tbody>
                {articlesData.data.entities.map((entity) => (
                  <Tr key={entity._id}>
                    <Td width={"10%"}>
                      <HStack>
                        <Badge colorScheme={entity.publishStatus === "Published" ? "green" : "gray"}>
                          {entity.publishStatus}
                        </Badge>
                        <IconButton
                          aria-label={"Edit Article"}
                          size={"xs"}
                          icon={<EditIcon />}
                          colorScheme={"linkedin"}
                          onClick={() => navigate(`/articles/${entity._id}`)}
                        />
                      </HStack>
                    </Td>
                    <Td width={"10%"}></Td>
                    <Td>{entity.title}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </TableContainer>
          <Pagination
            take={articlesData.data.take}
            skip={articlesData.data.skip}
            count={articlesData.data.count}
            style={{ mx: "auto", my: 3 }}
            onPageClick={handlePaginationPageChange}
          />
        </Box>
      </LoaderWrapper>
    </Box>
  );
};
