import { CrossBuyLink, Game } from "../../../../api/games/game.model";
import { Box, Button, Divider, Flex, Tag, useBoolean, useDisclosure, useToast, VStack } from "@chakra-ui/react";
import { LoaderWrapper } from "../../../../components/ListLoaderWrapper/LoaderWrapper";
import { ArrowDownIcon, ArrowUpIcon, DeleteIcon, SmallAddIcon } from "@chakra-ui/icons";
import React, { useEffect, useRef, useState } from "react";
import { Store } from "../../../../api/stores/store.model";
import { Platform } from "../../../../api/platforms/platform.model";
import { useObjArrByKey } from "../../../../hooks/useObjArrKey";
import { gameService } from "../../../../api/games/game.service";
import { GenericFormModalV2 } from "../../../../components/GenericFormModalV2/GenericFormModalV2";
import { useGenericFormV2 } from "../../../../components/GenericFormV2/use-generic-form-v2";
import { GenericFormV2ControlType, GenericFormV2SelectOption } from "../../../../components/GenericFormV2/types";
import { genericFormV2OptionsCreator } from "../../../../components/GenericFormV2/helpers/generic-form-v2-options-creator";
import { GameCrossplayVerification } from "./components/GameCrossplayVerification/GameCrossplayVerification";
import { BuyLink } from "../../../../api/shared/buyLink.model";

type Props = {
  game: Game;
  stores: Store[];
  platforms: Platform[];
  onSaveSuccessfully: () => Promise<void>;
  updateMemoryGame: (updateFn: (prev: Game) => Game) => any;
};

const convertBuyLinkCrossesIntoStrings = (crossBuyLinks: Game["crosses"]): Array<string[]> => {
  return crossBuyLinks.map((crossBuyLink) => crossBuyLink.map((crossBuyLink) => crossBuyLink._id));
};

export const GameCrosses = (props: Props) => {
  const toast = useToast();

  const [loading, updateLoading] = useBoolean(false);
  const [selectedCrossIndex, setSelectedCrossIndex] = useState<number | null>(null);

  const addBuyLinkModal = useDisclosure();

  const buyLinkByKey = useObjArrByKey(props.game.buyLinks);
  const storeByKey = useObjArrByKey(props.stores);
  const platformByKey = useObjArrByKey(props.platforms);

  const [localCrosses, setLocalCrosses] = useState<Game["crosses"]>(props.game.crosses);

  const addBuyLinkToCrossForm = useGenericFormV2({
    initialValues: {
      buyLinkId: "",
    },
    onSubmit: ({ buyLinkId }) => {
      if (selectedCrossIndex === null) {
        return;
      }

      const buyLink = props.game.buyLinks.find((buyLink) => buyLink._id === buyLinkId) as BuyLink;

      setLocalCrosses((prev) =>
        prev.map((cross, index) => {
          if (index !== selectedCrossIndex) {
            return cross;
          }

          return [...cross, { _id: buyLink._id, store: buyLink.store, platform: buyLink.platform }];
        })
      );

      addBuyLinkModal.onClose();
    },
    controls: [
      {
        name: "buyLinkId",
        label: "Buy Link",
        type: GenericFormV2ControlType.Select,
        options: [],
        disabled: false,
      },
    ],
  });

  const getAvailableBuyLinksForCross = (cross: CrossBuyLink[]) => {
    return props.game.buyLinks.filter((buyLink) => {
      return !cross.find((crossBuyLink) => crossBuyLink._id === buyLink._id);
    });
  };

  useEffect(() => {
    if (typeof selectedCrossIndex !== "number") {
      addBuyLinkToCrossForm.updateControlOptions("buyLinkId", []);
      return;
    }

    const selectedCross = localCrosses[selectedCrossIndex];

    const availableBuyLinksForSelectedCross = getAvailableBuyLinksForCross(selectedCross);

    const options = genericFormV2OptionsCreator(
      availableBuyLinksForSelectedCross,
      "_id",
      (element) => `${platformByKey[element.platform].name} | ${storeByKey[element.store].name}`
    );

    addBuyLinkToCrossForm.updateControlOptions("buyLinkId", options);

    if (options.length) {
      addBuyLinkToCrossForm.setFieldValue("buyLinkId", options[0].value);
    }
  }, [addBuyLinkModal.isOpen]);

  const initialRef = useRef(false);

  useEffect(() => {
    if (initialRef.current) {
      return;
    }

    setLocalCrosses(props.game.crosses);
  }, []);

  const saveCrosses = async () => {
    updateLoading.on();

    try {
      await gameService.updateCrosses(props.game._id, convertBuyLinkCrossesIntoStrings(localCrosses));

      toast({
        title: "Crosses was updated",
        status: "success",
      });

      props
        .onSaveSuccessfully()
        .catch(() =>
          toast({
            title: "Could not refetch Game, please reload a page",
            status: "warning",
          })
        )
        .then(() => updateLoading.off());
    } catch (e) {
      toast({
        title: "Crosses was not updated",
        status: "error",
      });
      console.log(e);
    }
  };

  useEffect(() => {
    props.updateMemoryGame((prev) => ({ ...prev, crosses: localCrosses }));
  }, [localCrosses]);

  const addCross = () => {
    setLocalCrosses((prev) => [...prev, []]);
  };

  const removeCross = (crossIndex: number) => {
    setLocalCrosses((prev) => prev.filter((_, index) => index !== crossIndex));
  };

  const openAddBuyLinkModal = (crossIndex: number) => {
    setSelectedCrossIndex(crossIndex);
    addBuyLinkModal.onOpen();
  };

  const closeAddBuyLinkModal = () => {
    setSelectedCrossIndex(null);
    addBuyLinkModal.onClose();
  };

  const moveBuyLinkUp = (buyLinkIndex: number, crossIndex: number) => {
    const currentBuyLink = localCrosses[crossIndex][buyLinkIndex];
    const nextBuyLink = localCrosses[crossIndex][buyLinkIndex - 1];

    setLocalCrosses((prev) =>
      prev.map((cross, arrCrossIndex) => {
        if (arrCrossIndex !== crossIndex) {
          return cross;
        }

        return cross.map((buyLink, index) => {
          if (index === buyLinkIndex) {
            return nextBuyLink;
          }

          if (index === buyLinkIndex - 1) {
            return currentBuyLink;
          }

          return buyLink;
        });
      })
    );
  };

  const moveBuyLinkDown = (buyLinkIndex: number, crossIndex: number) => {
    const currentBuyLink = localCrosses[crossIndex][buyLinkIndex];
    const nextBuyLink = localCrosses[crossIndex][buyLinkIndex + 1];

    setLocalCrosses((prev) =>
      prev.map((cross, arrCrossIndex) => {
        if (arrCrossIndex !== crossIndex) {
          return cross;
        }

        return cross.map((buyLink, index) => {
          if (index === buyLinkIndex) {
            return nextBuyLink;
          }

          if (index === buyLinkIndex + 1) {
            return currentBuyLink;
          }

          return buyLink;
        });
      })
    );
  };

  const removeBuyLinkFromCross = (buyLinkIndex: number, crossIndex: number) => {
    setLocalCrosses((prev) =>
      prev.map((cross, crossIndexInArr) => {
        if (crossIndexInArr !== crossIndex) {
          return cross;
        }

        return cross.filter((_, index) => buyLinkIndex !== index);
      })
    );
  };

  return (
    <Box>
      {props.game.crosses && (
        <Box>
          <GameCrossplayVerification
            game={props.game}
            onSaveSuccessfully={props.onSaveSuccessfully}
            updateMemoryGame={props.updateMemoryGame}
          />
          <Divider orientation="horizontal" my="6" />
        </Box>
      )}
      <Box mb={2}>
        <Tag>Crosses List</Tag>
      </Box>
      <Button onClick={addCross} leftIcon={<SmallAddIcon />} mb={2}>
        Add Cross
      </Button>
      <LoaderWrapper
        noData={!props.game.crosses.length && !localCrosses.length}
        initialLoading={false}
        loading={loading}
      >
        <VStack spacing={2} align={"stretch"}>
          {localCrosses.map((cross, crossIndex) => {
            return (
              <Box key={crossIndex} borderRadius={10} border="1px" borderColor="gray.200" p={3}>
                <VStack wrap={"wrap"} rowGap={2} columnGap={2} align={"stretch"} spacing={0}>
                  {cross.map((crossBuyLink, buyLinkIndex) => {
                    const store = storeByKey[crossBuyLink.store];
                    const platform = platformByKey[crossBuyLink.platform];

                    return (
                      <Flex
                        key={crossBuyLink._id}
                        borderRadius={"md"}
                        border="1px"
                        borderColor="gray.200"
                        pl={0}
                        align={"center"}
                      >
                        <Flex columnGap={0} mr={2}>
                          <Button
                            size={"xs"}
                            disabled={buyLinkIndex === 0}
                            onClick={() => moveBuyLinkUp(buyLinkIndex, crossIndex)}
                          >
                            <ArrowUpIcon />
                          </Button>
                          <Button
                            disabled={buyLinkIndex === cross.length - 1}
                            size={"xs"}
                            onClick={() => moveBuyLinkDown(buyLinkIndex, crossIndex)}
                          >
                            <ArrowDownIcon />
                          </Button>
                        </Flex>
                        {platform.name} | {store.name}
                        <Button
                          ml={"auto"}
                          colorScheme={"red"}
                          size={"sm"}
                          onClick={() => removeBuyLinkFromCross(buyLinkIndex, crossIndex)}
                        >
                          <DeleteIcon />
                        </Button>
                      </Flex>
                    );
                  })}
                  <Flex columnGap={2} pt={cross.length ? 2 : 0}>
                    <Button leftIcon={<DeleteIcon />} colorScheme={"red"} onClick={() => removeCross(crossIndex)}>
                      Remove Cross
                    </Button>
                    <Button
                      leftIcon={<SmallAddIcon />}
                      onClick={() => openAddBuyLinkModal(crossIndex)}
                      disabled={!getAvailableBuyLinksForCross(cross).length}
                    >
                      Add Buy Link
                    </Button>
                  </Flex>
                </VStack>
              </Box>
            );
          })}
        </VStack>
        <Divider />
        <Flex justify={"flex-end"} mt={2}>
          <Button variant={"outline"} colorScheme={"green"} onClick={saveCrosses} isLoading={loading}>
            Save
          </Button>
        </Flex>
      </LoaderWrapper>
      <GenericFormModalV2
        title={"Add Buy Link To Cross"}
        isOpen={addBuyLinkModal.isOpen}
        onClose={closeAddBuyLinkModal}
        actionLoading={false}
        onAction={addBuyLinkToCrossForm.formik.submitForm}
        controls={addBuyLinkToCrossForm.controls}
        formik={addBuyLinkToCrossForm.formik}
      />
    </Box>
  );
};
