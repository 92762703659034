import { Box, Divider, IconButton, Table, TableContainer, Tbody, Td, Tr } from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Game } from "../../../../api/games/game.model";

type Props = {
  games: Game[];
  onUpdateClick: (game: Game) => void;
  applySortPriorityToGame: (gameId: string, sortPriority: number | string) => Promise<void>;
};

export const SortingTable = (props: Props) => {
  const { games, onUpdateClick, applySortPriorityToGame } = props;

  return (
    <TableContainer>
      <Divider />
      <Table size="sm">
        <Tbody>
          {games.map((game) => (
            <Tr key={game.appSpecificConfig.crossplay.staticSortPriority}>
              <Td width="5%">
                <Box>{game.appSpecificConfig.crossplay.staticSortPriority}</Box>
              </Td>
              <Td>
                <Box>{game.name}</Box>
              </Td>
              <Td width="5%" textAlign="center" paddingX={0}>
                <IconButton
                  aria-label="Edit game"
                  variant="ghost"
                  size="sm"
                  onClick={() => onUpdateClick(game)}
                  icon={<EditIcon color="blue" />}
                />
              </Td>
              <Td width="5%" textAlign="center" paddingX={0}>
                <IconButton
                  aria-label="Delete game"
                  variant="ghost"
                  size="sm"
                  onClick={() => applySortPriorityToGame(game._id, 0)}
                  icon={<DeleteIcon color="red" />}
                />
              </Td>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  );
};
