import React from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Container,
  CloseButton,
} from "@chakra-ui/react";
import { GamesFilters } from "../GamesFilters/GamesFilters";
import { Pagination } from "../../../../components/Pagination/Pagination";
import { LoaderWrapper } from "../../../../components/ListLoaderWrapper/LoaderWrapper";
import { GameCard } from "./components/gameCard/gameCard";
import { useSearchModal } from "./hooks/useSearchModal";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClickGameCard: (gameID: string) => void;
};

export const GamesSearchWidgetModal = (props: Props) => {
  const searchModal = useSearchModal();

  const onClick = (gameID: string) => {
    props.onClickGameCard(gameID);
    props.onClose();
  };

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} size="4xl" scrollBehavior="inside">
      <ModalOverlay />
      <ModalContent>
        <CloseButton position="absolute" right={0} m={1} onClick={props.onClose} />
        <ModalHeader textAlign="center">Search games</ModalHeader>
        <ModalBody>
          <Container padding={3}>
            <GamesFilters onFiltersUpdate={searchModal.handleFilterUpdate} />
            <LoaderWrapper
              noData={searchModal.gamesData.hasBeenLoaded && !searchModal.gamesData.data.entities.length}
              initialLoading={searchModal.gamesData.loading && !searchModal.gamesData.hasBeenLoaded}
              loading={searchModal.gamesData.loading}
            >
              <SimpleGrid columns={3} columnGap={5} spacing={3} mt={5}>
                {searchModal.gamesData.data.entities.map((game) => (
                  <GameCard
                    key={game._id}
                    gameId={game._id}
                    name={game.name}
                    onClickGameCard={onClick}
                    posterUrl={game.image.posterUrl}
                    isLoading={searchModal.gamesData.loading}
                  />
                ))}
              </SimpleGrid>
            </LoaderWrapper>
          </Container>
        </ModalBody>
        <ModalFooter justifyContent="center">
          {searchModal.gamesData.data.count > searchModal.gamesData.data.take && (
            <Pagination
              take={searchModal.gamesData.data.take}
              skip={searchModal.gamesData.data.skip}
              count={searchModal.gamesData.data.count}
              style={{ mx: "auto", my: 3 }}
              onPageClick={searchModal.handlePaginationPageChange}
            />
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
