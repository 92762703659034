import { useEffect, useState } from "react";
import { PlayMode } from "../api/play-modes/play-mode.model";
import { PlayModeService } from "../api/play-modes/play-mode.service";

export const usePlayModes = () => {
  const [list, setList] = useState<PlayMode[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasBeenLoaded, setHasBeenLoaded] = useState<boolean>(false);

  const load = async () => {
    setLoading(true);

    try {
      const { data } = await PlayModeService.retrieveAll();
      setList(data);
      setLoading(false);
      setHasBeenLoaded(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    load().then().catch();
  }, []);

  const refetch = () => {
    load();
  };

  return {
    list,
    refetch,

    loading,
    hasBeenLoaded,
  };
};
