import { Game, GameSocial } from "../../../../api/games/game.model";
import { Box, Button, Divider, IconButton, Table, TableContainer, Tbody, Td, Tr, useToast } from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useSocials } from "../../../../hooks/useSocials";
import { DeleteIcon, EditIcon, PlusSquareIcon } from "@chakra-ui/icons";
import { AddSocialToGameModal } from "./components/AddSocialToGameModal/AddSocialToGameModal";
import { UpdateGameSocialModal } from "./components/UpdateGameSocialModal/UpdateGameSocialModal";
import { isAxiosError } from "axios";
import { gameService } from "../../../../api/games/game.service";

type Props = {
  game: Game;
  refetchGame: () => Promise<void>;
  updateMemoryGame: (updateFn: (prev: Game) => Game) => any;
};

export const GameSocialsTab = (props: Props) => {
  const toast = useToast();
  const socialsData = useSocials();

  const addSocialToGameModalRefController = useRef<AddSocialToGameModal["RefController"]>(null);
  const updateGameSocialModalRefController = useRef<UpdateGameSocialModal["RefController"]>(null);

  const [localGameSocials, setLocalGameSocials] = useState<GameSocial[]>(props.game.socials);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const addSocialToGame: AddSocialToGameModal["Props"]["onSave"] = (data) => {
    setLocalGameSocials((prev) => [
      ...prev,
      {
        _id: data.social._id,
        name: data.social.name,
        slug: data.social.slug,
        url: data.url,
      },
    ]);
  };

  const removeSocialFromGame = (gameSocial: GameSocial) => {
    setLocalGameSocials((prev) => prev.filter((element) => element._id !== gameSocial._id));
  };

  const updateGameSocial: UpdateGameSocialModal["Props"]["onSave"] = (nextGameSocialId: string, url: string) => {
    setLocalGameSocials((prev) =>
      prev.map((element) => {
        if (element._id !== nextGameSocialId) {
          return element;
        }

        return {
          ...element,
          url,
        };
      })
    );
  };

  useEffect(() => {
    props.updateMemoryGame((prev) => ({
      ...prev,
      socials: localGameSocials,
    }));
  }, [localGameSocials]);

  const openAddSocialsModal = () => {
    if (!addSocialToGameModalRefController.current) {
      return;
    }

    addSocialToGameModalRefController.current.open();
  };

  const save = async () => {
    setSaveLoading(true);

    const socialsUpdateList = localGameSocials.map((social) => ({ socialId: social._id, url: social.url }));

    try {
      await gameService.updateSocials(props.game._id, socialsUpdateList);
      toast({
        title: "Game Socials were successfully created",
        status: "success",
      });

      props.refetchGame().catch(() =>
        toast({
          title: "Could not refetch Game, please reload a page",
          status: "warning",
        })
      );
    } catch (err) {
      toast({
        title: "Unexpected error. Please reload page and try again",
        status: "error",
      });
    }

    setSaveLoading(false);
  };

  return (
    <Box>
      <Box>
        <Button
          leftIcon={<PlusSquareIcon />}
          colorScheme="teal"
          variant="outline"
          onClick={openAddSocialsModal}
          size="sm"
        >
          Add Social
        </Button>
      </Box>
      <TableContainer my={4}>
        <Divider />
        <Table size="sm">
          <Tbody>
            {localGameSocials.map((gameSocial) => (
              <Tr key={gameSocial._id}>
                <Td width="5%">
                  <Box>{gameSocial.name}</Box>
                </Td>
                <Td width="5%">
                  <Box>{gameSocial.url}</Box>
                </Td>
                <Td width="5%" textAlign="center" paddingX={0}>
                  <IconButton
                    aria-label="Edit game"
                    variant="ghost"
                    size="sm"
                    onClick={() => updateGameSocialModalRefController.current?.open(gameSocial)}
                    icon={<EditIcon color="blue" />}
                  />
                </Td>
                <Td width="5%" textAlign="center" paddingX={0}>
                  <IconButton
                    aria-label={""}
                    variant="ghost"
                    size="sm"
                    onClick={() => removeSocialFromGame(gameSocial)}
                    icon={<DeleteIcon color="red" />}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box>
        <Button
          variant={"outline"}
          colorScheme={"green"}
          onClick={save}
          isLoading={saveLoading}
          isDisabled={saveLoading}
        >
          Save
        </Button>
      </Box>
      <AddSocialToGameModal
        ref={addSocialToGameModalRefController}
        allSocialsList={socialsData.list}
        currentSocialList={localGameSocials}
        onSave={addSocialToGame}
      />
      <UpdateGameSocialModal ref={updateGameSocialModalRefController} onSave={updateGameSocial} />
    </Box>
  );
};
