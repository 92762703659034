import axios, { AxiosResponse } from "axios";
import { Admin } from "./admin.model";
import { endpoints } from "./endpoints";

class AdminService {
  isProd(): Promise<AxiosResponse<Admin>> {
    return axios.get(endpoints.isAdmin.original)
  }
}

export const adminService = new AdminService()