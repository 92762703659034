import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Social } from "../../../../../../api/socials/social.model";

type RefController = {
  open: () => void;
  clear: () => void;
  switchLoading: (next: boolean) => void;
};

type Props = {
  allSocialsList: Social[];
  currentSocialList: Pick<Social, "_id">[];
  onSave: (data: { social: Social; url: string }) => void;
};

export const AddSocialToGameModal = forwardRef((props: Props, ref: ForwardedRef<RefController>) => {
  const modalState = useDisclosure();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const socialsSelectRef = useRef<HTMLSelectElement>(null);

  const [urlInputError, setUrlInputError] = useState<null | string>(null);
  const urlInputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    open: () => {
      console.log(urlInputRef.current)
      modalState.onOpen();
    },
    clear,
    switchLoading: (next) => setIsLoading(next),
  }));

  const clear = () => {
    if (!urlInputRef.current) {
      return;
    }

    setUrlInputError(null);
    urlInputRef.current.value = "";
  };

  const handleClose = () => {
    modalState.onClose();
    clear();
  };

  const handleSave = () => {
    if (!socialsSelectRef.current || !urlInputRef.current) {
      return;
    }

    const socialId = socialsSelectRef.current.value;
    const url = urlInputRef.current.value;

    if (!url.trim().length) {
      setUrlInputError("Cannot be empty");
      return;
    }

    try {
      new URL(url);
    } catch {
      setUrlInputError("Provided URL is not url");
      return;
    }

    if (!socialId.trim().length) {
      return;
    }

    const social = props.allSocialsList.find((element) => element._id === socialId);

    if (!social) {
      return;
    }

    props.onSave({
      social,
      url,
    });
  };

  const socialListAvailableForSelect = props.allSocialsList.filter((social) => {
    return !props.currentSocialList.find((localSocial) => localSocial._id === social._id);
  });

  return (
    <Modal isOpen={modalState.isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Social</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Social</FormLabel>
            <Select ref={socialsSelectRef}>
              {socialListAvailableForSelect.map((option) => {
                return (
                  <option key={option._id} value={option._id}>
                    {option.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <FormControl isInvalid={typeof urlInputError === "string"}>
            <FormLabel>URL</FormLabel>
            <Input ref={urlInputRef} type="text" onChange={() => setUrlInputError(null)} />
            <FormErrorMessage>{urlInputError}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button onClick={handleClose}>Close</Button>
            <Button
              variant={"outline"}
              colorScheme={"green"}
              onClick={handleSave}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export type AddSocialToGameModal = {
  Props: Props;
  RefController: RefController;
};
