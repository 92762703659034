import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { GenericFormV2, GenericFormV2Props } from "../GenericFormV2/GenericFormV2";

export type GenericFormModalV2Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  actionText?: string;
  actionDisabled?: boolean;
  actionLoading: boolean;
  onAction: () => void;
  cancelText?: string;
  children?: JSX.Element;
} & GenericFormV2Props;

export const GenericFormModalV2 = (props: GenericFormModalV2Props) => {
  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>
          {props.children}
          <GenericFormV2 controls={props.controls} formik={props.formik} />
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button onClick={props.onClose}>{props.cancelText || "Cancel"}</Button>
            <Button
              variant={"outline"}
              colorScheme={"green"}
              isLoading={props.actionLoading}
              onClick={props.onAction}
              isDisabled={props.actionDisabled}
            >
              {props.actionText || "Save"}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
