import { GameFilter } from "../types/game-filter";

export const extractGameFilterFromUrl = (queryStr: string): GameFilter => {
  const possibleFilterKeys: Record<keyof GameFilter, 1> = {
    search: 1,
    publishStatus: 1,
    crossplayVerification: 1,
  };

  const keys = Object.keys(possibleFilterKeys) as (keyof GameFilter)[];


  const searchParams = new URLSearchParams(queryStr);

  return keys.reduce<GameFilter>((params, key) => {
    const paramValue = searchParams.get(key);

    if (paramValue !== null) {
      // @ts-ignore
      params[key] = paramValue;
    }

    return params;
  }, {});
};
