import { FormControl, FormErrorMessage, FormLabel, Input, Select, VStack } from "@chakra-ui/react";
import { useFormik } from "formik";
import React, { ChangeEvent, forwardRef, useImperativeHandle } from "react";
import { FormikConfig } from "formik/dist/types";

type Option = {
  value: string;
  label: string;
};

export type Control =
  | {
      label: string;
      name: string;
      defaultValue?: string;
      inputType?: void;
      disabled?: boolean
    }
  | {
      label: string;
      name: string;
      defaultValue?: string;
      inputType: "select";
      options: Option[];
      computeOptions?: (options: Option[], formValues: Record<string, string>) => Option[];
      disabled?: boolean
    };

export type ControlsValues<T extends Control[]> = Record<T[number]["name"], string>;

type Props = {
  controls: Control[];
  controlsValidation?: any;
  onSubmit: FormikConfig<Record<string, string>>["onSubmit"];
  onControlChange?: (controlName: string, controlValue: string, formikInstance: ReturnType<typeof useFormik>) => void;
};

const generateInitialValues = <T extends Control[]>(controls: T) => {
  return controls.reduce((prev, curr) => {
    if (curr.inputType === "select") {
      return {
        ...prev,
        [curr.name]: curr.options[0].value,
      };
    }

    return { ...prev, [curr.name]: curr.defaultValue || "" };
  }, {});
};

export const GenericForm = forwardRef((props: Props, ref) => {
  const initialValues: ControlsValues<Props["controls"]> = generateInitialValues(props.controls);

  const formik = useFormik({
    initialValues,
    validationSchema: props.controlsValidation,
    onSubmit: async (...args) => {
      await props.onSubmit(...args);
    },
  });

  const onControlChange = (event: ChangeEvent<HTMLSelectElement | HTMLInputElement>, controlName: string) => {
    formik.handleChange(event);

    if (props.onControlChange) {
      props.onControlChange(controlName, event.currentTarget.value, formik);
    }
  };

  useImperativeHandle(
    ref,
    () => ({
      submit: formik.submitForm,
    }),
    []
  );

  return (
    <VStack spacing={4}>
      {props.controls.map((control) => {
        const optionsComputedFunction =
          control.inputType === "select"
            ? control.computeOptions
              ? control.computeOptions
              : (entities: Option[]) => entities
            : (entities: Option[]) => entities;

        return (
          <FormControl key={control.name} isInvalid={!!formik.errors[control.name] && !!formik.touched[control.name]}>
            <FormLabel>{control.label}</FormLabel>
            {!control.inputType && (
              <Input
                name={control.name}
                value={formik.values[control.name]}
                onChange={(event) => onControlChange(event, control.name)}
                onBlur={formik.handleBlur}
                disabled={control.disabled}
              />
            )}
            {control.inputType === "select" && (
              <Select
                name={control.name}
                value={formik.values[control.name]}
                onChange={(event) => onControlChange(event, control.name)}
                onBlur={formik.handleBlur}
                disabled={control.disabled}
              >
                {optionsComputedFunction(control.options, formik.values).map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </Select>
            )}
            <FormErrorMessage>{formik.errors[control.name]}</FormErrorMessage>
          </FormControl>
        );
      })}
    </VStack>
  );
});

export type GenericFormProps = Props;
