import React from "react";
import { Box, Heading, Image, Skeleton } from "@chakra-ui/react";

const s3url = process.env.REACT_APP_ASSETS_URL;

type Props = {
  isLoading: boolean;
  gameId: string;
  name: string;
  onClickGameCard: (gameId: string) => void;
  posterUrl: string | null;
};

export const GameCard = (props: Props) => {
  return (
    <Skeleton isLoaded={!props.isLoading}>
      <Box>
        <Heading isTruncated size="xs" padding={2} textAlign="center">
          {props.name}
        </Heading>
        {props.posterUrl ? (
          <Image
            transition=".3s ease-in-out"
            _hover={{
              cursor: "pointer",
              transform: "scale(1.05)",
              boxShadow: "rgb(38, 57, 77) 0 10px 30px -10px;",
            }}
            onClick={() => props.onClickGameCard(props.gameId)}
            boxSize="200px"
            objectFit="cover"
            src={`${s3url}/${props.posterUrl}`}
          />
        ) : (
          <Box boxSize="180" backgroundColor="gray.200" />
        )}
      </Box>
    </Skeleton>
  );
};
