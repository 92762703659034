export const util_arr_findObjInArr = <T extends object, K extends keyof T>(
  arr: T[],
  key: K,
  value: T[K]
): { element: T; index: number } | null => {
  const index = arr.findIndex((elem) => elem[key] === value);
  const element = arr[index];

  if (!element) {
    return null;
  }

  return {
    element,
    index,
  };
};
