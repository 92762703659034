import { Game } from "../../../../api/games/game.model";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Switch,
  Tag,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { gameService } from "../../../../api/games/game.service";

type Props = {
  game: Game;
  refetchGame: () => Promise<void>;
  updateMemoryGame: (updateFn: (prev: Game) => Game) => any;
};

export const GameFeaturesTab = (props: Props) => {
  const toast = useToast();

  const [crossProgressionSupport, setCrossProgressionSupport] = useState<boolean>(props.game.crossProgression.support);
  const [crossProgressionVerificationUrl, setCrossProgressionVerificationUrl] = useState<string | null>(
    props.game.crossProgression.verificationUrl
  );
  const [crossProgressionLoading, setCrossProgressionLoading] = useState<boolean>(false);

  useEffect(() => {
    props.updateMemoryGame((prev) => ({
      ...prev,
      crossProgression: {
        support: crossProgressionSupport,
        verificationUrl: crossProgressionVerificationUrl,
      },
    }));
  }, [crossProgressionSupport, crossProgressionVerificationUrl]);

  const saveCrossProgression = async () => {
    if (crossProgressionSupport) {
      if (typeof crossProgressionVerificationUrl !== "string") {
        return;
      }

      if (crossProgressionVerificationUrl.trim().length === 0) {
        return;
      }

      try {
        new URL(crossProgressionVerificationUrl);
      } catch {
        toast({
          title: "Cross-Progression URL must be URL",
          status: "warning",
        });
        return;
      }
    }

    setCrossProgressionLoading(true);

    try {
      await gameService.updateCrossProgression(props.game._id, {
        support: crossProgressionSupport,
        verificationUrl: crossProgressionVerificationUrl,
      });

      toast({
        title: "Cross-Progression was successfully updated",
        status: "success",
      });

      props
        .refetchGame()
        .catch(() =>
          toast({
            title: "Could not refetch Game, please reload a page",
            status: "warning",
          })
        )
        .then(() => setCrossProgressionLoading(false));
    } catch (err) {
      toast({
        title: "Cross-Progression was not updated",
        status: "error",
      });
      console.log(err);
    }
  };

  return (
    <Box>
      <Box>
        <Box>
          <Tag>Cross-Progression</Tag>
        </Box>
        <Box>
          <Box mt={4}>
            <Switch
              size="lg"
              isChecked={crossProgressionSupport}
              onChange={(e) => setCrossProgressionSupport(e.target.checked)}
            />
          </Box>
          {crossProgressionSupport && (
            <Box width={600}>
              <FormControl
                isInvalid={
                  crossProgressionVerificationUrl === null || crossProgressionVerificationUrl.trim().length === 0
                }
              >
                <FormLabel>Cross-Progression Verification URL</FormLabel>
                <Input
                  type="text"
                  value={crossProgressionVerificationUrl || ""}
                  onChange={(e) => setCrossProgressionVerificationUrl(e.target.value)}
                />
                <FormErrorMessage>Cannot be empty and should be URL</FormErrorMessage>
              </FormControl>
            </Box>
          )}
          <Button
            mt={4}
            variant={"outline"}
            colorScheme={"green"}
            isLoading={crossProgressionLoading}
            onClick={saveCrossProgression}
          >
            Save Cross-Progression
          </Button>
        </Box>
      </Box>
      <Divider mt={2} />
    </Box>
  );
};
