import { ForwardedRef, forwardRef, useImperativeHandle, useState } from "react";
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  useDisclosure,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import { Social } from "../../../../api/socials/social.model";

type RefController = {
  open: (genre: Social) => void;
  switchLoading: (next: boolean) => void;
  clear: () => void;
  close: () => void;
};

type Props = {
  onRemove: (genre: Social) => void;
};

export const RemoveSocialModal = forwardRef((props: Props, ref: ForwardedRef<RefController>) => {
  const modalState = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [social, setSocial] = useState<Social | null>(null);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setSocial(data);
      modalState.onOpen();
    },
    close: () => {
      modalState.onClose();
    },
    clear,
    switchLoading: (next) => setIsLoading(next),
  }));

  const clear = () => {
    setIsLoading(false);
    setSocial(null);
  };

  const handleClose = () => {
    modalState.onClose();
    clear();
  };

  const handleRemove = () => {
    if (!social) {
      return;
    }

    props.onRemove(social);
  };

  return (
    <Modal isOpen={modalState.isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Remove Social</ModalHeader>
        <ModalBody>
          <WarningIcon color="yellow.400" /> Are you sure you want to remove <Tag>{social?.name}</Tag>?
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button onClick={handleClose}>Close</Button>
            <Button
              variant={"outline"}
              colorScheme={"green"}
              onClick={handleRemove}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Confirm
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export type RemoveSocialModal = {
  Props: Props;
  RefController: RefController;
};
