import { GenericFormV2SelectOption } from "../types";

export const genericFormV2OptionsCreator = <T extends Record<any, any>[]>(
  arr: T,
  valueKey: keyof T[number],
  labelKey: keyof T[number] | ((element: T[number]) => GenericFormV2SelectOption["label"])
): GenericFormV2SelectOption[] => {
  return arr.map((elem) => {
    const value = elem[valueKey];

    const label = typeof labelKey === "function" ? labelKey(elem) : elem[labelKey];

    return { value, label };
  });
};
