import { Game, GamePlayMode } from "../../../../api/games/game.model";
import {
  Box,
  Button,
  Checkbox, Code,
  Flex,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  useBoolean,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { gameService } from "../../../../api/games/game.service";
import { usePlayModes } from "../../../../hooks/usePlayModes";

type Props = {
  game: Game;
  refetchGame: () => Promise<void>;
  updateMemoryGame: (updateFn: (prev: Game) => Game) => any;
};

export const GamePlayModesTab = (props: Props) => {
  const toast = useToast();

  const [loading, updateLoading] = useBoolean(false);
  const [localPlayModes, setLocalPlayModes] = useState<Game["playModes"]>(props.game.playModes);

  const playModesData = usePlayModes();

  useEffect(() => {
    setLocalPlayModes(props.game.playModes);
  }, [props.game.playModes]);

  useEffect(() => {
    props.updateMemoryGame((prev) => ({ ...prev, playModes: localPlayModes }));
  }, [localPlayModes]);

  const updateLocal = async (nextStatus: boolean, playMode: GamePlayMode) => {
    if (nextStatus) {
      setLocalPlayModes((prev) => [...prev, playMode]);
      return;
    }

    setLocalPlayModes((prev) => prev.filter((element) => element._id !== playMode._id));
  };

  const update = async () => {
    updateLoading.on();

    try {
      await gameService.updatePlayModes(
        props.game._id,
        localPlayModes.map((element) => element._id)
      );

      toast({
        title: "PlayModes were successfully updated",
        status: "success",
      });

      props
        .refetchGame()
        .catch(() =>
          toast({
            title: "Could not refetch Game, please reload a page",
            status: "warning",
          })
        )
        .then(() => updateLoading.off());
    } catch (err) {
      toast({
        title: "PlayModes were not updated",
        status: "error",
      });
      console.log(err);
    }
  };

  const handleFromClipboard = async () => {
    const text = await navigator.clipboard.readText();

    const playModesToAdd: GamePlayMode[] = [];

    for (const playMode of playModesData.list) {
      if (!text.toLowerCase().includes(playMode.name.toLowerCase())) {
        continue;
      }

      const alreadyExist = !!localPlayModes.find((element) => element._id === playMode._id);

      if (alreadyExist) {
        continue;
      }

      playModesToAdd.push({
        _id: playMode._id,
        name: playMode.name,
        slug: playMode.slug,
      });
    }

    if (!playModesToAdd.length) {
      return;
    }

    setLocalPlayModes((prev) => [...prev, ...playModesToAdd]);
  };

  return (
    <Box>
      <Box mb={4}>
        <Button size={"sm"} onClick={handleFromClipboard} colorScheme={"facebook"}>
          From Clipboard
        </Button>{" "}
        <Code colorScheme={"orange"}>Extract play modes from clipboard string</Code>
      </Box>
      <TableContainer>
        <Table size="sm">
          <Tbody>
            {playModesData.list.map((playMode) => (
              <Tr key={playMode._id}>
                <Td width="2%" textAlign="center" paddingX={0}>
                  <Checkbox
                    onChange={(e) => updateLocal(e.target.checked, playMode)}
                    colorScheme="green"
                    isChecked={!!localPlayModes.find((value) => value._id === playMode._id)}
                  />
                </Td>
                <Td>
                  <Box>{playMode.name}</Box>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Flex mt={2} justifyContent={"flex-end"}>
        <Button variant={"outline"} colorScheme={"green"} isLoading={loading} onClick={update}>
          Save
        </Button>
      </Flex>
    </Box>
  );
};
