import { Game } from "../../../api/games/game.model";
import { Button, Flex, FormControl, FormLabel, Input, Textarea, useBoolean, useToast, VStack } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import { gameService } from "../../../api/games/game.service";
import { LoaderWrapper } from "../../../components/ListLoaderWrapper/LoaderWrapper";
import { convertUnixIntoMonthCalendarFormat } from "../../../utils/date/convert-unix-into-month-calendar-format";
import { convertMonthCalendarFormatIntoUnix } from "../../../utils/date/convert-month-calendar-format-into-unix";

type Props = {
  game: Game;
  onSaveSuccessfully: () => Promise<void>;
  updateMemoryGame: (updateFn: (prev: Game) => Game) => any;
};

export const GameBasicInfo = (props: Props) => {
  const toast = useToast();
  const [loading, updateLoading] = useBoolean(false);

  const formik = useFormik({
    initialValues: {
      name: props.game.name,
      description: props.game.description,
      releaseDate: convertUnixIntoMonthCalendarFormat(props.game.releaseDate),
    },
    onSubmit: async ({ name, description, releaseDate }) => {
      updateLoading.on();

      try {
        await gameService.update(props.game._id, {
          name,
          description,
          releaseDate: convertMonthCalendarFormatIntoUnix(releaseDate),
        });

        toast({
          title: "Basic Info was successfully updated",
          status: "success",
        });

        props
          .onSaveSuccessfully()
          .catch(() =>
            toast({
              title: "Could not refetch Game, please reload a page",
              status: "warning",
            })
          )
          .then(() => updateLoading.off());
      } catch (err) {
        toast({
          title: "Basic Info was not updated",
          status: "error",
        });
        console.log(err);
      }
    },
  });

  useEffect(() => {
    props.updateMemoryGame((prev) => ({
      ...prev,
      name: formik.values.name,
      description: formik.values.description,
      releaseDate: convertMonthCalendarFormatIntoUnix(formik.values.releaseDate),
    }));
  }, [formik.values.name, formik.values.description, formik.values.releaseDate]);

  return (
    <LoaderWrapper noData={false} initialLoading={false} loading={loading}>
      <VStack spacing={2}>
        <FormControl>
          <FormLabel>Name</FormLabel>
          <Input placeholder={"Game name"} name={"name"} onChange={formik.handleChange} value={formik.values.name} />
        </FormControl>
        <FormControl>
          <FormLabel>Description</FormLabel>
          <Textarea name={"description"} onChange={formik.handleChange} value={formik.values.description} rows={20} />
        </FormControl>
        <div style={{ alignSelf: "flex-start" }}>prev: {(props.game as any).releaseDateTemp}</div>
        <FormControl>
          <Input type={"date"} name={"releaseDate"} onChange={formik.handleChange} value={formik.values.releaseDate} />
        </FormControl>
      </VStack>
      <Flex justifyContent={"flex-end"} mt={2}>
        <Button variant={"outline"} colorScheme={"green"} onClick={formik.submitForm} isLoading={loading}>
          Save
        </Button>
      </Flex>
    </LoaderWrapper>
  );
};
