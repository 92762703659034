import { Box, Button, ChakraProps, Flex, HStack, IconButton } from "@chakra-ui/react";
import { memo } from "react";
import { getPaginationTotalPages } from "../../utils/pagination/get-pagination-total-pages";
import { getPaginationCurrentPage } from "../../utils/pagination/get-pagination-current-page";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

type PaginationElement =
  | {
      type: "page";
      pageNumber: number;
    }
  | {
      type: "space-between";
      position: number;
    };

type Props = {
  take: number;
  skip: number;
  count: number;
  style?: ChakraProps;
  onPageClick: (skip: number) => void;
};

const HALF_AMOUNT_OF_REQUIRED_TO_SHOW_PAGES = 3;

export const Pagination = memo((props: Props) => {
  const totalPages = getPaginationTotalPages(props.take, props.count);
  const currentPage = getPaginationCurrentPage(props.take, props.skip, props.count);

  const handleAnotherPageClick = (nextPage: number) => {
    props.onPageClick((nextPage - 1) * props.take);
  };

  const paginationElements = ((): PaginationElement[] => {
    const start = currentPage - HALF_AMOUNT_OF_REQUIRED_TO_SHOW_PAGES;
    const end = currentPage + HALF_AMOUNT_OF_REQUIRED_TO_SHOW_PAGES;

    const arrayOfPageNumbers = Array(totalPages)
      .fill(null)
      .map((_, index) => index + 1)
      .filter((page, index, arr) => {
        // We should not keep first and last elements
        if (index === 0 || index === arr.length - 1) {
          return true;
        }

        return page >= start && page <= end;
      });

    const arrayOfPaginationElements: PaginationElement[] = [];

    arrayOfPageNumbers.forEach((page, index, arr) => {
      if (index === 0 && currentPage - page > HALF_AMOUNT_OF_REQUIRED_TO_SHOW_PAGES + 1) {
        arrayOfPaginationElements.push(
          {
            type: "page",
            pageNumber: page,
          },
          {
            type: "space-between",
            position: 1,
          }
        );

        return;
      }

      if (index === arr.length - 1 && page - currentPage > HALF_AMOUNT_OF_REQUIRED_TO_SHOW_PAGES + 1) {
        arrayOfPaginationElements.push(
          {
            type: "space-between",
            position: 2,
          },
          {
            type: "page",
            pageNumber: page,
          }
        );

        return;
      }

      arrayOfPaginationElements.push({
        type: "page",
        pageNumber: page,
      });
    });

    return arrayOfPaginationElements;
  })();

  const createPageButton = (page: number, current: number) => (
    <Button colorScheme={page === current ? "green" : "blue"} onClick={() => handleAnotherPageClick(page)} key={page}>
      {page}
    </Button>
  );

  return (
    <Flex {...(props.style ? props.style : {})} display={"inline-flex"}>
      <IconButton aria-label={"prev"} disabled={currentPage === 1} onClick={() => handleAnotherPageClick(1)}>
        <ChevronLeftIcon />
      </IconButton>
      <HStack mx={3} spacing={1}>
        {paginationElements.map((paginationElement, index, arr) => {
          if (paginationElement.type === "page") {
            return createPageButton(paginationElement.pageNumber, currentPage);
          }

          return (
            <Button disabled={true} key={`position-${paginationElement.position}`}>
              ...
            </Button>
          );
        })}
      </HStack>
      <IconButton
        aria-label={"next"}
        disabled={currentPage === totalPages}
        onClick={() => handleAnotherPageClick(currentPage + 1)}
      >
        <ChevronRightIcon />
      </IconButton>
    </Flex>
  );
});
