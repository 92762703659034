import { useGamesSearchWidget } from "../../../useGamesSearchWidget";
import { useEffect, useRef } from "react";
import { GameFilter } from "../../../../../pages/GamesListPage/types/game-filter";

export const useSearchModal = () => {
  const gamesData = useGamesSearchWidget({ initialFilter: {}, initialPagination: null, addQueryParams: false });

  const scrollElementRef = useRef<HTMLElement | null>(null);

  useEffect(() => {
    gamesData.load({
      filter: {},
      resetPagination: true,
    });
  }, []);

  const handleFilterUpdate = (filter: GameFilter) => {
    gamesData.load({
      filter,
      resetPagination: true,
    });
  };

  const handlePaginationPageChange = (skip: number) => {
    gamesData.load({ pagReq: { skip } }, () => {
      document.getElementById("search-games-modal-body")?.scrollTo({ top: 0, behavior: "smooth" });
    });
  };

  return {
    handlePaginationPageChange,
    handleFilterUpdate,
    gamesData,
    scrollElementRef,
  };
};
