export enum StoreName {
  Playstation = "Playstation Store",
  EpicGames = "Epic Games Store",
  Microsoft = "Microsoft Store",
  Xbox = "Xbox Store",
  Nintendo = "Nintendo Store",
  Steam = "Steam Store",
  ElectronicArts = "Electronic Arts Store",
  Ubisoft = "Ubisoft Store",
  BattleNet = "Battle.net Store",
}
