import React, { useState } from "react";
import { Box, Button, Code, FormControl, Input, VStack } from "@chakra-ui/react";
import { genName } from "../../../../utils/components/genName";
import { FilterCrossplayVerificationFilterValues, GameFilter } from "../../../../pages/GamesListPage/types/game-filter";
import { FilterPublishStatus } from "./components/FilterPublishStatus/FilterPublishStatus";
import { FilterCrossplayVerifiedStatus } from "./components/FilterCrossplayVerifiedStatus/FilterCrossplayVerifiedStatus";

type Props = {
  onFiltersUpdate: (filters: GameFilter) => void;
  initialFilter?: GameFilter;
};

export const GamesFilters = (props: Props) => {
  const [search, setSearch] = useState<string>(props.initialFilter?.search || "");
  const [publishStatus, setPublishStatus] = useState<GameFilter["publishStatus"] | "Any">(
    props?.initialFilter?.publishStatus || "Any"
  );
  const [crossplayVerificationStatus, setCrossplayVerificationStatus] = useState<
    FilterCrossplayVerificationFilterValues | "Any"
  >("Any");

  const submit = (e: any) => {
    e.preventDefault();

    const params: GameFilter = {
      search,
    };

    if (publishStatus !== "Any") {
      params.publishStatus = publishStatus;
    }

    if (crossplayVerificationStatus !== "Any") {
      params.crossplayVerification = crossplayVerificationStatus;
    }

    props.onFiltersUpdate(params);
  };

  return (
    <Box pb={2} as={"form"} {...genName(GamesFilters)} onSubmit={submit} backgroundColor={"#fff"} zIndex={2}>
      <Code mb={2} colorScheme={"orange"}>
        Games Filtering
      </Code>
      <Box mb={2}>
        <FormControl>
          <Input placeholder="Search game" type="search" value={search} onChange={(e) => setSearch(e.target.value)} />
        </FormControl>
        <VStack spacing={2} alignItems={"flex-start"} mt={4}>
          <FilterPublishStatus status={publishStatus} updateStatus={setPublishStatus} />
          <FilterCrossplayVerifiedStatus
            status={crossplayVerificationStatus}
            updateStatus={setCrossplayVerificationStatus}
          />
        </VStack>
      </Box>
      <Button colorScheme={"green"} size={"sm"} onClick={submit}>
        Search
      </Button>
    </Box>
  );
};
