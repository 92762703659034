import ColorThief from "colorthief";

export const generateGradient = async (imgURL: string, imgElement: HTMLImageElement): Promise<string> => {
  return new Promise((resolve) => {
    const onLoad = () => {
      const generator = new ColorThief();

      const [first, second] = generator.getPalette(imgElement, 2);

      resolve(`linear-gradient(90deg, rgb(${first.join(",")}) 0%, rgb(${second.join(",")}) 100%)`);

      imgElement.removeEventListener("load", onLoad);
    };

    imgElement.addEventListener("load", onLoad);

    imgElement.src = imgURL;
  });
};
