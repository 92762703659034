import { PlatformName } from "../../api/platforms/platform-names.enum";
import { StoreName } from "../../api/stores/store-names.enum";

export const storesByPlatformConfig: Record<PlatformName, StoreName[]> = {
  [PlatformName.Playstation4]: [StoreName.Playstation],
  [PlatformName.Playstation5]: [StoreName.Playstation],
  [PlatformName.XboxOne]: [StoreName.Xbox],
  [PlatformName.XboxXS]: [StoreName.Xbox],
  [PlatformName.NintendoSwitch]: [StoreName.Nintendo],
  [PlatformName.Windows]: [
    StoreName.Steam,
    StoreName.BattleNet,
    StoreName.EpicGames,
    StoreName.ElectronicArts,
    StoreName.Microsoft,
    StoreName.Ubisoft,
    StoreName.Xbox,
  ],
};
