import axios, { AxiosResponse } from "axios";
import { endpoints } from "./endpoints";
import { Social } from "./social.model";

type Create = {
  name: string;
};

export class SocialService {
  static create = async (data: Create): Promise<AxiosResponse<Social>> => {
    return axios.post(endpoints.create.insert(), {
      name: data.name,
    });
  };

  static retrieveAll = async (): Promise<AxiosResponse<Social[]>> => {
    return axios.get(endpoints.create.insert());
  };

  static remove = async (socialId: string): Promise<AxiosResponse<void>> => {
    return axios.delete(endpoints.remove.insert({ socialId }));
  };
}
