import { Box, Button, Divider, Table, TableContainer, Tag, Tbody, Td, Tr, useToast } from "@chakra-ui/react";
import { LoaderWrapper } from "../../components/ListLoaderWrapper/LoaderWrapper";
import React, { useRef } from "react";
import { useGenres } from "../../hooks/useGenres";
import { CreateGenreModal } from "./components/CreateGenreModal/CreateGenreModal";
import { DeleteIcon, SmallAddIcon } from "@chakra-ui/icons";
import { GenreService } from "../../api/genres/genre.service";
import { isAxiosError } from "axios";
import { RemoveGenreModal } from "./components/RemoveGenreModal/RemoveGenreModal";
import { Genre } from "../../api/genres/genre.model";

export const GenresListPage = () => {
  const toast = useToast();
  const genresData = useGenres();

  const createGenreModalRefController = useRef<CreateGenreModal["RefController"]>(null);
  const removeGenreModalRefController = useRef<RemoveGenreModal["RefController"]>(null);

  const createGenre: CreateGenreModal["Props"]["onSave"] = async (data) => {
    if (!createGenreModalRefController.current) {
      return;
    }

    createGenreModalRefController.current.switchLoading(true);

    try {
      await GenreService.create(data);
      toast({
        title: "Genre was successfully created",
        status: "success",
      });

      genresData.refetch();
      createGenreModalRefController.current.clear();
    } catch (err) {
      if (isAxiosError(err)) {
        if (err.response?.data.message?.includes("GENRE_ALREADY_EXISTS")) {
          toast({
            title: "Genre with such name already exists",
            status: "error",
          });
          return;
        }
      }

      toast({
        title: "Unexpected error. Please reload page and try again",
        status: "error",
      });
    } finally {
      createGenreModalRefController.current.switchLoading(false);
    }
  };

  const removeGenre: RemoveGenreModal["Props"]["onRemove"] = async (genre) => {
    if (!removeGenreModalRefController.current) {
      return;
    }

    removeGenreModalRefController.current.switchLoading(true);

    try {
      await GenreService.remove(genre._id);
      toast({
        title: "Genre was successfully deleted",
        status: "success",
      });

      genresData.refetch();
      removeGenreModalRefController.current.clear();
      removeGenreModalRefController.current.close();
    } catch (err) {
      toast({
        title: "Unexpected error. Please reload page and try again",
        status: "error",
      });
    } finally {
      removeGenreModalRefController.current.switchLoading(false);
    }
  };

  const handleRemoveClick = (genre: Genre) => {
    if (!removeGenreModalRefController.current) {
      return;
    }

    removeGenreModalRefController.current.open(genre);
  };

  return (
    <Box>
      <Button onClick={() => createGenreModalRefController.current?.open()} leftIcon={<SmallAddIcon />}>
        Create Genre
      </Button>
      <Divider orientation="horizontal" my="6" />
      <LoaderWrapper
        noData={genresData.hasBeenLoaded && !genresData.list.length}
        initialLoading={genresData.loading && !genresData.hasBeenLoaded}
        loading={genresData.loading}
      >
        <TableContainer>
          <Table size="sm">
            <Tbody>
              {genresData.list.map((genre) => (
                <Tr key={genre._id}>
                  <Td>
                    <Box>
                      <Tag>{genre.name}</Tag> - <Tag>{genre.slug}</Tag>
                    </Box>
                  </Td>
                  <Td width="5%" textAlign="center" paddingX={0}>
                    <Button leftIcon={<DeleteIcon />} colorScheme="red" onClick={() => handleRemoveClick(genre)}>
                      Remove
                    </Button>
                  </Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </LoaderWrapper>
      <CreateGenreModal ref={createGenreModalRefController} onSave={createGenre} />
      <RemoveGenreModal ref={removeGenreModalRefController} onRemove={removeGenre} />
    </Box>
  );
};
