import { BuyLink } from "../../../api/shared/buyLink.model";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Grid,
  HStack,
  Text,
  useBoolean,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { GenericConfirmModal } from "../../../components/GenericConfirmModal/GenericConfirmModal";
import { gameService } from "../../../api/games/game.service";
import { Platform } from "../../../api/platforms/platform.model";
import { Store } from "../../../api/stores/store.model";
import React, { useEffect, useMemo, useState } from "react";
import { getErrorMessage } from "../../../utils/errors/getErrorMessage";
import { useGenericFormV2 } from "../../../components/GenericFormV2/use-generic-form-v2";
import { GenericFormV2ControlType } from "../../../components/GenericFormV2/types";
import { GenericFormModalV2 } from "../../../components/GenericFormModalV2/GenericFormModalV2";
import { storeRegularExpressionsConfig } from "../../../utils/stores/store-regular-expressions.config";
import { getStoreNameById } from "../../../utils/stores/get-store-name-by-id";

type Props = {
  buyLink: BuyLink;
  gameId: string;
  platforms: Platform[];
  stores: Store[];
  onRemoveSuccessfully: () => Promise<void>;
  onSaveSuccessfully: () => Promise<void>;
};

export const GameBuyLinkCardItem = (props: Props) => {
  const toast = useToast();

  const [removeLoading, updateRemoveLoading] = useBoolean(false);
  const removeModalDisclosure = useDisclosure();

  const [updateLoading, updateUpdateLoading] = useBoolean();
  const updateModal = useDisclosure();

  const [updateIsDisabled, setUpdateIsDisabled] = useState<boolean>(false);

  const updateBuyLinkForm = useGenericFormV2({
    initialValues: {
      url: props.buyLink.url,
    },
    controls: [
      {
        type: GenericFormV2ControlType.Text,
        name: "url",
        label: "URL",
      },
    ],
    onSubmit: async ({ url }, formikHelpers) => {
      updateUpdateLoading.on();
      try {
        await gameService.updateBuyLink(props.gameId, {
          ...props.buyLink,
          url,
        });

        toast({
          title: "Buy link was successfully updated",
          status: "success",
        });
        props
          .onSaveSuccessfully()
          .catch(() =>
            toast({
              title: "Could not refetch Game, please reload a page",
              status: "warning",
            })
          )
          .then(() => {
            updateUpdateLoading.off();
            updateModal.onClose();
          });
      } catch (err) {
        toast({
          title: "Buy link was not updated",
          status: "error",
        });
        console.log(err);
      }
    },
  });

  useEffect(() => {
    updateBuyLinkForm.setFieldValue("url", props.buyLink.url);
  }, [props.buyLink]);

  useEffect(() => {
    try {
      const storeName = getStoreNameById(props.buyLink.store, props.stores);
      const regExp = storeRegularExpressionsConfig[storeName];

      if (!regExp.test(updateBuyLinkForm.values.url)) {
        setUpdateIsDisabled(true);
        return;
      }

      setUpdateIsDisabled(false);
    } catch (err) {
      setUpdateIsDisabled(true);
    }
  }, [updateBuyLinkForm.values.url]);

  const remove = async () => {
    updateRemoveLoading.on();

    try {
      await gameService.removeBuyLink(props.gameId, props.buyLink._id);

      toast({
        title: "Buy Link has been removed",
        status: "success",
      });

      props
        .onRemoveSuccessfully()
        .catch(() =>
          toast({
            title: "Could not refetch Game, please reload a page",
            status: "warning",
          })
        )
        .then(() => {
          removeModalDisclosure.onClose();
        });
    } catch (err) {
      toast({
        title: "Buy Link has not been removed",
        description: getErrorMessage(err),
        status: "error",
      });
      console.log(err);
    } finally {
      updateRemoveLoading.off();
    }
  };

  const platformName = useMemo(
    () => props.platforms.find((platform) => platform._id === props.buyLink.platform)!.name,
    [props.platforms, props.buyLink]
  );

  const storeName = useMemo(
    () => props.stores.find((store) => store._id === props.buyLink.store)!.name,
    [props.stores, props.buyLink]
  );

  return (
    <Card direction={"row"}>
      <CardBody>
        <Grid templateColumns={"83px 1fr"}>
          <Text fontWeight="medium">Platform: </Text>
          <Text fontWeight="normal">{platformName}</Text>
          <Text fontWeight="medium">Store: </Text>
          <Text fontWeight="normal">{storeName}</Text>
          <Text fontWeight="medium">Url: </Text>
          <Text fontWeight="normal">{props.buyLink.url}</Text>
        </Grid>
      </CardBody>
      <CardFooter>
        <HStack spacing={2}>
          <Button
            leftIcon={<DeleteIcon />}
            colorScheme="red"
            isLoading={removeLoading}
            onClick={removeModalDisclosure.onOpen}
          >
            Remove
          </Button>
          <Button leftIcon={<EditIcon />} isLoading={removeLoading} onClick={updateModal.onOpen}>
            Update
          </Button>
        </HStack>
      </CardFooter>
      <GenericConfirmModal
        title={"Are you sure you want to remove store?"}
        isOpen={removeModalDisclosure.isOpen}
        onClose={removeModalDisclosure.onClose}
        actionText={"Remove"}
        actionLoading={removeLoading}
        onAction={remove}
        type={"remove"}
      />
      <GenericFormModalV2
        title={"Update Buy Link"}
        isOpen={updateModal.isOpen}
        onClose={updateModal.onClose}
        actionLoading={updateLoading}
        actionDisabled={updateIsDisabled}
        onAction={updateBuyLinkForm.formik.submitForm}
        controls={updateBuyLinkForm.controls}
        formik={updateBuyLinkForm.formik}
      />
    </Card>
  );
};
