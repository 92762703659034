import { Badge, ChakraProps, Flex, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import React from "react";
import { genName } from "../../../../../../utils/components/genName";
import { GameFilter } from "../../../../../../pages/GamesListPage/types/game-filter";

type Props = {
  status: GameFilter["publishStatus"] | "Any";
  updateStatus: (nextPublishStatus: GameFilter["publishStatus"] | "Any") => void;
  style?: ChakraProps;
};

export const FilterPublishStatus = (props: Props) => {
  return (
    <Flex {...(props.style ? props.style : {})} {...genName(FilterPublishStatus)}>
      <Badge display={"flex"} alignItems={"center"} mr={2}>
        Publish Status
      </Badge>
      <RadioGroup onChange={(v) => props.updateStatus(v as any)} value={props.status}>
        <Stack direction="row">
          <Radio value={"Published"}>Published</Radio>
          <Radio value={"Draft"}>Draft</Radio>
          <Radio value={"Any"}>Any</Radio>
        </Stack>
      </RadioGroup>
    </Flex>
  );
};
