import { Game } from "../../../api/games/game.model";
import { Box, Button, Divider, Stack, useBoolean, useDisclosure, useToast } from "@chakra-ui/react";
import { Store } from "../../../api/stores/store.model";
import { Platform } from "../../../api/platforms/platform.model";
import { SmallAddIcon } from "@chakra-ui/icons";
import React, { useState } from "react";
import { gameService } from "../../../api/games/game.service";
import { LoaderWrapper } from "../../../components/ListLoaderWrapper/LoaderWrapper";
import { GameBuyLinkCardItem } from "./GameBuyLinkCardItem";
import { GameBuyLinkModal } from "./GameBuyLinkCreateModal/GameBuyLinkModal";

type Props = {
  game: Game;
  stores: Store[];
  platforms: Platform[];
  onSaveSuccessfully: () => Promise<void>;
  onRefresh: () => Promise<void>;
};

export const GameBuyLinks = (props: Props) => {
  const toast = useToast();

  const createModal = useDisclosure();

  const [createLoading, updateCreateLoading] = useBoolean();
  const [createBuyLinkResetId, setCreateBuyLinkResetId] = useState<Required<GameBuyLinkModal["Props"]>["resetId"]>(
    Date.now()
  );

  const actionHandler: GameBuyLinkModal["Props"]["onAction"] = async ({ url, storeId, platformId }) => {
    updateCreateLoading.on();

    try {
      await gameService.createBuyLink(props.game._id, {
        url,
        platform: platformId,
        store: storeId,
      });

      toast({
        title: "Buy link was successfully created",
        status: "success",
      });
      props
        .onSaveSuccessfully()
        .catch(() =>
          toast({
            title: "Could not refetch Game, please reload a page",
            status: "warning",
          })
        )
        .then(() => {
          updateCreateLoading.off();
          createModal.onClose();
          setCreateBuyLinkResetId(Date.now());
        });
    } catch (err) {
      toast({
        title: "Buy link was not created",
        status: "error",
      });
      console.log(err);
    }
  };

  return (
    <Box>
      <Button onClick={createModal.onOpen} leftIcon={<SmallAddIcon />}>
        Add Buy Link
      </Button>
      <Divider orientation="horizontal" my="6" />
      <LoaderWrapper noData={!props.game.buyLinks.length} initialLoading={false} loading={createLoading}>
        <Stack spacing="4">
          {props.game.buyLinks.map((buyLink) => {
            return (
              <GameBuyLinkCardItem
                key={buyLink._id}
                buyLink={buyLink}
                gameId={props.game._id}
                stores={props.stores}
                platforms={props.platforms}
                onRemoveSuccessfully={props.onRefresh}
                onSaveSuccessfully={props.onRefresh}
              />
            );
          })}
        </Stack>
      </LoaderWrapper>
      <GameBuyLinkModal
        resetId={createBuyLinkResetId}
        platforms={props.platforms}
        stores={props.stores}
        buyLinks={props.game.buyLinks}
        mode={GameBuyLinkModal.mode.Create}
        isOpen={createModal.isOpen}
        onClose={createModal.onClose}
        actionLoading={createLoading}
        onAction={actionHandler}
      />
    </Box>
  );
};
