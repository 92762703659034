import { Box, Divider, Stack } from "@chakra-ui/react";
import React, { useState } from "react";
import { LoaderWrapper } from "../../components/ListLoaderWrapper/LoaderWrapper";
import { useGamesSearchWidget } from "../../widgets/GamesSearchWidget/useGamesSearchWidget";
import { GameCardItem } from "./components/GameCardItem/GameCardItem";
import { GamesFilters } from "../../widgets/GamesSearchWidget/components/GamesFilters/GamesFilters";
import { GameFilter } from "./types/game-filter";
import { extractGameFilterFromUrl } from "./utils/extract-game-filter-from-url";
import { Pagination } from "../../components/Pagination/Pagination";
import { genName } from "../../utils/components/genName";
import { PagReq } from "../../api/shared/pagReq.model";
import { extractPaginationFromUrl } from "../../utils/pagination/extract-pagination-from-url";
import { GameCreateSection } from "./components/GameCreateSection/GameCreateSection";
import { GamesAppConfig } from "./components/GamesAppConfig/GamesAppConfig";

export const GamesListPage = () => {
  const [initialFilter] = useState<GameFilter>(extractGameFilterFromUrl(window.location.search));
  const [initialPagination] = useState<PagReq | null>(extractPaginationFromUrl(window.location.search));

  const gamesSearchWidget = useGamesSearchWidget({ initialFilter, initialPagination });

  const handleFilterUpdate = (filter: GameFilter) => {
    gamesSearchWidget.load(
      {
        filter,
        resetPagination: true,
      },
      () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    );
  };

  const handlePaginationPageChange = (skip: number) => {
    gamesSearchWidget.load({ pagReq: { skip } }, () => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    });
  };

  return (
    <Box {...genName(GamesListPage)}>
      <GamesAppConfig style={{ mb: 2 }} />
      <GameCreateSection style={{ mb: 2 }} onCreateSuccessfully={gamesSearchWidget.refetch} />
      <GamesFilters initialFilter={initialFilter} onFiltersUpdate={handleFilterUpdate} />
      <Divider orientation="horizontal" my="6" />
      <LoaderWrapper
        noData={gamesSearchWidget.hasBeenLoaded && !gamesSearchWidget.data.entities.length}
        initialLoading={gamesSearchWidget.loading && !gamesSearchWidget.hasBeenLoaded}
        loading={gamesSearchWidget.loading}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Stack spacing="4">
            {gamesSearchWidget.data.entities.map((entity) => (
              <Box key={entity._id}>
                <GameCardItem game={entity} />
              </Box>
            ))}
          </Stack>
          <Pagination
            take={gamesSearchWidget.data.take}
            skip={gamesSearchWidget.data.skip}
            count={gamesSearchWidget.data.count}
            style={{ mx: "auto", my: 3 }}
            onPageClick={handlePaginationPageChange}
          />
        </Box>
      </LoaderWrapper>
    </Box>
  );
};
