export enum GameUrlTabNames {
  BasicInfo = "basic-info",
  SEO = "seo",
  BuyLinks = "buy-links",
  Crosses = "crosses",
  Image = "image",
  Gallery = "gallery",
  Genres = "genres",
  PlayModes = "play-modes",
  Features = "features",
  Socials = "socials",
  Articles = "articles",
}
