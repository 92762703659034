import { Box, Button, Divider, Stack } from "@chakra-ui/react";
import { LoaderWrapper } from "../../components/ListLoaderWrapper/LoaderWrapper";
import { Pagination } from "../../components/Pagination/Pagination";
import React from "react";
import { useArticles } from "../../hooks/useArticles";
import { useNavigate } from "react-router-dom";

export const ArticlesPage = () => {
  const navigate = useNavigate();
  const articlesData = useArticles();

  const handlePaginationPageChange = (skip: number) => {
    articlesData.load({ skip });
  };

  return (
    <Box>
      <Button onClick={() => navigate("/create-article")}>Create Article</Button>
      <Divider orientation="horizontal" my="6" />
      <LoaderWrapper
        noData={articlesData.hasBeenLoaded && !articlesData.data.entities.length}
        initialLoading={articlesData.loading && !articlesData.hasBeenLoaded}
        loading={articlesData.loading}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Stack spacing="4">
            {articlesData.data.entities.map((entity) => (
              <Box key={entity._id}>{entity.title}</Box>
            ))}
          </Stack>
          <Pagination
            take={articlesData.data.take}
            skip={articlesData.data.skip}
            count={articlesData.data.count}
            style={{ mx: "auto", my: 3 }}
            onPageClick={handlePaginationPageChange}
          />
        </Box>
      </LoaderWrapper>
    </Box>
  );
};
