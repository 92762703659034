import {
  Box,
  Button,
  HStack,
  Kbd,
  ListItem,
  Tag,
  Text,
  UnorderedList,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { Game } from "../../../../../../api/games/game.model";
import React, { useState } from "react";
import { AddIcon, DeleteIcon, EditIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { GenericFormModalV2 } from "../../../../../../components/GenericFormModalV2/GenericFormModalV2";
import { useGenericFormV2 } from "../../../../../../components/GenericFormV2/use-generic-form-v2";
import { GenericFormV2ControlType } from "../../../../../../components/GenericFormV2/types";
import { gameService } from "../../../../../../api/games/game.service";
import * as yup from "yup";

type Props = {
  game: Game;
  onSaveSuccessfully: () => Promise<void>;
  updateMemoryGame: (updateFn: (prev: Game) => Game) => any;
};

export const GameCrossplayVerification = (props: Props) => {
  const toast = useToast();

  const crossplayVerificationModal = useDisclosure();

  const [crossplayVerificationLoading, setCrossplayVerificationLoading] = useState<boolean>(false);
  const [crossplayVerificationRemoveId, setCrossplayVerificationRemoveId] = useState<string | null>(null);

  const [selectedCrossplayVerification, setSelectedCrossplayVerification] = useState<
    Game["crossplayVerification"][number] | null
  >(null);

  const createCrossplayVerification = async (url: string) => {
    setCrossplayVerificationLoading(true);

    try {
      await gameService.createCrossplayVerification(props.game._id, { url: url });

      props
        .onSaveSuccessfully()
        .then(() => {
          toast({
            title: "Crossplay verification was successfully created",
            status: "success",
          });
        })
        .catch(() => {
          toast({
            title: "Crossplay verification was successfully created, but game wasn't re-fetched, please reload a page",
            status: "warning",
          });
        })
        .finally(() => {
          closeCrossplayVerificationModal();
          setCrossplayVerificationLoading(false);
        });
    } catch (err) {
      setCrossplayVerificationLoading(false);
      toast({
        title: "Crossplay verification was not created",
        status: "error",
      });
    }
  };

  const updateCrossplayVerification = async (url: string, crossplayVerificationId: string) => {
    setCrossplayVerificationLoading(true);

    try {
      await gameService.updateCrossplayVerification(props.game._id, crossplayVerificationId, { url: url });

      props
        .onSaveSuccessfully()
        .then(() => {
          toast({
            title: "Crossplay verification was successfully updated",
            status: "success",
          });
        })
        .catch(() => {
          toast({
            title: "Crossplay verification was successfully updated, but game wasn't re-fetched, please reload a page",
            status: "warning",
          });
        })
        .finally(() => {
          closeCrossplayVerificationModal();
          setCrossplayVerificationLoading(false);
        });
    } catch (err) {
      setCrossplayVerificationLoading(false);
      toast({
        title: "Crossplay verification was not updated",
        status: "error",
      });
    }
  };

  const removeCrossplayVerification = async (crossplayVerification: Game["crossplayVerification"][number]) => {
    setCrossplayVerificationRemoveId(crossplayVerification._id);

    try {
      await gameService.removeCrossplayVerification(props.game._id, crossplayVerification._id);

      props
        .onSaveSuccessfully()
        .then(() => {
          toast({
            title: "Crossplay verification was successfully removed",
            status: "success",
          });
        })
        .catch(() => {
          toast({
            title: "Crossplay verification was successfully removed, but game wasn't re-fetched, please reload a page",
            status: "warning",
          });
        })
        .finally(() => {
          setCrossplayVerificationRemoveId(null);
        });
    } catch (err) {
      setCrossplayVerificationRemoveId(null);
      toast({
        title: "Crossplay verification was not removed",
        status: "error",
      });
    }
  };

  const crossplayVerificationForm = useGenericFormV2({
    initialValues: {
      url: "",
    },
    validationScheme: yup.object().shape({
      url: yup.string().url().required(),
    }),
    controls: [
      {
        type: GenericFormV2ControlType.Text,
        name: "url",
        label: "URL",
      },
    ],
    onSubmit: async (values) => {
      if (selectedCrossplayVerification) {
        await updateCrossplayVerification(values.url, selectedCrossplayVerification._id);
        return;
      }

      await createCrossplayVerification(values.url);
    },
  });

  const closeCrossplayVerificationModal = () => {
    setSelectedCrossplayVerification(null);
    crossplayVerificationForm.formik.resetForm();
    crossplayVerificationModal.onClose();
  };

  const crossplayVerificationEditHandler = (crossplayVerification: Game["crossplayVerification"][number]) => {
    setSelectedCrossplayVerification(crossplayVerification);
    crossplayVerificationForm.setFieldValue("url", crossplayVerification.url);
    crossplayVerificationModal.onOpen();
  };

  return (
    <Box>
      <Box mb={2}>
        <Tag>Crossplay Verification</Tag>
      </Box>
      <Box>
        {!props.game.crossplayVerification.length && (
          <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
            <WarningTwoIcon color={"red.500"} />
            <Box color={"red.500"} fontWeight={"500"}>
              Game does not have verified crossplay
            </Box>
            <Button mt={2} size={"sm"} colorScheme={"green"} onClick={crossplayVerificationModal.onOpen}>
              Add verification
            </Button>
          </Box>
        )}
        {!!props.game.crossplayVerification.length && (
          <Box>
            <UnorderedList spacing={3}>
              {props.game.crossplayVerification.map((crossplayVerification) => (
                <ListItem key={crossplayVerification._id}>
                  <HStack>
                    <Kbd py={1} px={3} backgroundColor={"purple.100"} borderBottomColor={"purple.300"} width={"300px"}>
                      <Text isTruncated>{crossplayVerification.url}</Text>
                    </Kbd>
                    <Button
                      size={"sm"}
                      colorScheme={"blue"}
                      onClick={() => crossplayVerificationEditHandler(crossplayVerification)}
                      disabled={crossplayVerificationRemoveId !== null}
                    >
                      <EditIcon />
                    </Button>
                    <Button
                      size={"sm"}
                      colorScheme={"red"}
                      isLoading={crossplayVerificationRemoveId === crossplayVerification._id}
                      onClick={() => removeCrossplayVerification(crossplayVerification)}
                    >
                      <DeleteIcon />
                    </Button>
                  </HStack>
                </ListItem>
              ))}
            </UnorderedList>
            <Button
              leftIcon={<AddIcon />}
              size={"xs"}
              mt={2}
              colorScheme={"green"}
              onClick={crossplayVerificationModal.onOpen}
            >
              Add
            </Button>
          </Box>
        )}
      </Box>
      <GenericFormModalV2
        title={"Add Crossplay Verification"}
        isOpen={crossplayVerificationModal.isOpen}
        onClose={closeCrossplayVerificationModal}
        actionLoading={crossplayVerificationLoading}
        onAction={crossplayVerificationForm.formik.submitForm}
        controls={crossplayVerificationForm.controls}
        formik={crossplayVerificationForm.formik}
      />
    </Box>
  );
};
