import { Game } from "../../../../api/games/game.model";
import { LoaderWrapper } from "../../../../components/ListLoaderWrapper/LoaderWrapper";
import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Image,
  Input,
  Slider,
  SliderFilledTrack,
  SliderMark,
  SliderThumb,
  SliderTrack,
  Switch,
  useBoolean,
  useToast,
  VStack,
} from "@chakra-ui/react";
import { useDropzone } from "react-dropzone";
import React, { useEffect, useRef, useState } from "react";
import { gameService } from "../../../../api/games/game.service";
import { uploaderService } from "../../../../api/uploader/uploader.service";
// @ts-ignore
import ColorThief from "colorthief/dist/color-thief.min";
import { fileToDataUrl } from "../../../../utils/file/file-to-data-url";

const s3url = process.env.REACT_APP_ASSETS_URL;

const getGradient = (img: HTMLImageElement): string => {
  const generator = new ColorThief();

  const [first, second] = generator.getPalette(img, 2);

  return `linear-gradient(90deg, rgb(${first.join(",")}) 0%, rgb(${second.join(",")}) 100%)`;
};

type Props = {
  game: Game;
  onSaveSuccessfully: () => Promise<void>;
  updateMemoryGame: (updateFn: (prev: Game) => Game) => any;
};

export const GameImages = (props: Props) => {
  const toast = useToast();

  const [loading, updateLoading] = useBoolean(false);

  const [posterRequiresShiftIfVerificationPersist, setPosterRequiresShiftIfVerificationPersist] = useState<boolean>(
    props.game.image.posterRequiresShiftIfVerificationPersist
  );

  const [posterImageFile, setPosterImageFile] = useState(null);
  const [bannerImageFile, setBannerImageFile] = useState(null);

  const [posterImageUrl, setPosterImageUrl] = useState<string | null>(
    props.game.image.posterUrl ? `${s3url}/${props.game.image.posterUrl}` : null
  );
  const [bannerImageUrl, setBannerImageUrl] = useState<string | null>(
    props.game.image.bannerUrl ? `${s3url}/${props.game.image.bannerUrl}` : null
  );

  const [bannerPositionY, setBannerPositionY] = useState<number>(props.game.image.bannerPositionY);

  const [posterGradient, setPosterGradient] = useState(props.game.image.posterGradient || "");
  const [bannerGradient, setBannerGradient] = useState(props.game.image.bannerGradient || "");

  const initialRef = useRef(false);

  const posterImageRef = useRef<HTMLImageElement | null>(null);
  const bannerImageRef = useRef<HTMLImageElement | null>(null);

  useEffect(() => {
    if (initialRef.current) {
      return;
    }

    setPosterImageFile(null);
    setBannerImageFile(null);
    setPosterGradient(props.game.image.posterGradient || "");
    setBannerGradient(props.game.image.bannerGradient || "");
    setPosterImageUrl(`${s3url}/${props.game.image.posterUrl}`);
    setBannerImageUrl(`${s3url}/${props.game.image.bannerUrl}`);
  }, [props.game]);

  const save = async () => {
    updateLoading.on();

    try {
      let posterImageValue = props.game.image.posterUrl;

      if (posterImageFile) {
        const formData = new FormData();
        formData.append("file", posterImageFile);

        const { data } = await uploaderService.upload(formData, { convertToWebp: true });

        posterImageValue = data.fileName;
      }

      let bannerImageValue = props.game.image.bannerUrl;

      if (bannerImageFile) {
        const formData = new FormData();
        formData.append("file", bannerImageFile);

        const { data } = await uploaderService.upload(formData, { convertToWebp: true });

        bannerImageValue = data.fileName;
      }

      await gameService.updateImage(props.game._id, {
        posterRequiresShiftIfVerificationPersist,
        posterUrl: posterImageValue,
        bannerUrl: bannerImageValue,
        posterGradient,
        bannerGradient,
        bannerPositionY,
      });

      toast({
        title: "Game image data was updated",
        status: "success",
      });

      props
        .onSaveSuccessfully()
        .catch(() =>
          toast({
            title: "Could not refetch Game, please reload a page",
            status: "warning",
          })
        )
        .then(() => updateLoading.off());
    } catch (err) {
      toast({
        title: "Could not update Game image data",
        status: "error",
      });
      console.log(err);
    }
  };

  const onDrop = (
    imageRef: typeof posterImageRef | typeof bannerImageRef,
    updateFileStateFunction: typeof setPosterImageFile | typeof setBannerImageFile,
    updateGradientStateFunction: typeof setPosterGradient | typeof setBannerGradient,
    updateImageUrlStateFunction: typeof setPosterImageUrl | typeof setBannerImageUrl,
    acceptedFiles: any[]
  ) => {
    updateFileStateFunction(acceptedFiles[0]);

    updateImageUrlStateFunction(URL.createObjectURL(acceptedFiles[0]));

    const originalImageURL = imageRef.current?.src;
    let iteration = 0;

    // This is small workaround for gradient generator package
    // For now we do not know how to detect that some element on page has been mounted,
    //  but gradient package require image to be present in DOM,
    //  so we just set interval which wait until image will be on the screen
    const interval = setInterval(() => {
      if (iteration > 20) {
        clearInterval(interval);
      }

      if (imageRef.current && originalImageURL !== imageRef.current.src) {
        updateGradientStateFunction(getGradient(imageRef.current));
        clearInterval(interval);
      }

      iteration += 1;
    }, 10);
  };

  const posterDropZone = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/webp": [".webp"],
    },
    onDrop: (acceptedFiles) =>
      onDrop(posterImageRef, setPosterImageFile, setPosterGradient, setPosterImageUrl, acceptedFiles),
  });

  const bannerDropZone = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/webp": [".webp"],
    },
    onDrop: (acceptedFiles) =>
      onDrop(bannerImageRef, setBannerImageFile, setBannerGradient, setBannerImageUrl, acceptedFiles),
  });

  useEffect(() => {
    (async () => {
      const [posterDataUrl, bannerDataUrl] = await Promise.all([
        fileToDataUrl(posterImageFile),
        fileToDataUrl(bannerImageFile),
      ]);

      props.updateMemoryGame((prev) => ({
        ...prev,
        image: {
          ...prev.image,
          posterRequiresShiftIfVerificationPersist,
          bannerPositionY,
          posterGradient,
          bannerGradient,
          posterUrl: posterDataUrl || props.game.image.posterUrl,
          bannerUrl: bannerDataUrl || props.game.image.bannerUrl,
        },
      }));
    })();
  }, [posterRequiresShiftIfVerificationPersist, posterGradient, bannerGradient, posterImageFile, bannerImageFile, bannerPositionY]);

  return (
    <LoaderWrapper noData={false} initialLoading={false} loading={loading}>
      <FormLabel>Poster Requires Shift If Verification Persist</FormLabel>
      <Switch
        isChecked={posterRequiresShiftIfVerificationPersist}
        onChange={(e) => setPosterRequiresShiftIfVerificationPersist(e.target.checked)}
      />
      <VStack spacing={2}>
        <FormControl>
          <FormLabel>Poster Image</FormLabel>
          <Box
            w="100%"
            __css={{
              "> div": {
                padding: "40px",
                marginBottom: 2,
                textAlign: "center",
                borderRadius: 10,
                border: "1px",
                borderColor: "gray.200",
                transition: "background-color 0.3s ease-in-out",
                cursor: "pointer",
              },
              "> div:hover": {
                backgroundColor: "blue.100",
              },
            }}
          >
            <div {...posterDropZone.getRootProps()}>
              <input {...posterDropZone.getInputProps()} />
              {posterDropZone.isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
            </div>
          </Box>
          {posterImageUrl && (
            <Box height={"300px"} __css={{ "> img": { height: "100%" } }}>
              <Image ref={posterImageRef} src={posterImageUrl} />
            </Box>
          )}
        </FormControl>
        <FormControl>
          <FormLabel>
            Poster Gradient
            <Input value={posterGradient} onChange={(e) => setPosterGradient(e.currentTarget.value)} />
          </FormLabel>
        </FormControl>
        <Box w="100%" h="40px" background={posterGradient || "black"} />

        <FormControl>
          <FormLabel>Banner Image</FormLabel>
          <Box
            w="100%"
            __css={{
              "> div": {
                padding: "40px",
                marginBottom: 2,
                textAlign: "center",
                borderRadius: 10,
                border: "1px",
                borderColor: "gray.200",
                transition: "background-color 0.3s ease-in-out",
                cursor: "pointer",
              },
              "> div:hover": {
                backgroundColor: "blue.100",
              },
            }}
          >
            <div {...bannerDropZone.getRootProps()}>
              <input {...bannerDropZone.getInputProps()} />
              {bannerDropZone.isDragActive ? (
                <p>Drop the files here ...</p>
              ) : (
                <p>Drag 'n' drop some files here, or click to select files</p>
              )}
            </div>
          </Box>
          {bannerImageUrl && (
            <Box height={"300px"} __css={{ "> img": { height: "100%" } }}>
              <Image ref={bannerImageRef} src={bannerImageUrl} />
            </Box>
          )}
        </FormControl>
        <FormControl>
          <FormLabel>Banner Position Y</FormLabel>
          <Slider
            min={0}
            max={100}
            defaultValue={0}
            onChange={(val) => {
              setBannerPositionY(val);
            }}
          >
            <SliderMark value={bannerPositionY} textAlign="center" bg="blue.500" color="white" mt="-10" ml="-5" w="12">
              {bannerPositionY}%
            </SliderMark>
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb boxSize={7} />
          </Slider>
        </FormControl>
        <FormControl>
          <FormLabel>
            Banner Gradient
            <Input value={bannerGradient} onChange={(e) => setBannerGradient(e.currentTarget.value)} />
          </FormLabel>
        </FormControl>
        <Box w="100%" h="40px" background={bannerGradient || "black"} />
      </VStack>
      <Divider />
      <Flex justify={"flex-end"} mt={2}>
        <Button variant={"outline"} colorScheme={"green"} onClick={save} isLoading={loading}>
          Save
        </Button>
      </Flex>
    </LoaderWrapper>
  );
};
