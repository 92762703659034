import { Box, Button, FormControl, FormLabel, Input, Textarea, useToast, VStack } from "@chakra-ui/react";
import { Image } from "../../components/Image/Image";
import { useImages } from "../../components/Image/useImages";
import { useLocation } from "react-router";
import { useEffect, useRef, useState } from "react";
import { gameService } from "../../api/games/game.service";
import { Game } from "../../api/games/game.model";
import { ArticleService } from "../../api/articles/article.service";
import { ArticleBelongingType, ArticleType } from "../../api/articles/article.model";
import { v4 } from "uuid";

function calculateReadTime(text: string): number {
  // Function to remove HTML tags
  const removeHtmlTags = (html: string): string => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  // Clean the text by removing HTML tags
  const cleanText = removeHtmlTags(text);

  // Calculate the number of words
  const wordCount = cleanText.split(/\s+/).filter((word) => word.length > 0).length;

  // Calculate reading time (in minutes)
  const readTime = Math.ceil(wordCount / 200); // average reading speed

  return readTime;
}

export const ArticleCreatePage = () => {
  const toast = useToast();

  const [gameLoading, setGameLoading] = useState<boolean>(true);
  const [gameIdIsNotValid, setGameIdIsNotValid] = useState<boolean>(false);
  const [game, setGame] = useState<Game | null>(null);
  const [articleLoading, setArticleLoading] = useState<boolean>(false);
  const sessionIdRef = useRef<string>("");

  const titleInputRef = useRef<HTMLInputElement>({} as HTMLInputElement);
  const shortDescriptionRef = useRef<HTMLTextAreaElement>({} as HTMLTextAreaElement);
  const rawHTMLRef = useRef<HTMLTextAreaElement>({} as HTMLTextAreaElement);

  const galleryImages = useImages({ createDefaultIfNotExists: true });
  const location = useLocation();

  useEffect(() => {
    const gameId = new URLSearchParams(location.search).get("gameId");

    if (!gameId) {
      setGameIdIsNotValid(true);
      return;
    }

    (async () => {
      try {
        const { data } = await gameService.retrieveById(gameId);

        setGame(data);
      } catch (err) {
        setGameIdIsNotValid(true);
      } finally {
        setGameLoading(false);

        const sessionId = new URLSearchParams(location.search).get("sessionId");

        if (sessionId) {
          sessionIdRef.current = sessionId;

          const data = JSON.parse(sessionStorage.getItem(sessionId) as string) as Partial<{
            title: string;
            shortDescription: string;
            rawHTML: string;
          }>;

          if (data?.title?.trim().length) {
            console.log("ss: ", titleInputRef.current);
            titleInputRef.current.value = data.title;
          }

          if (data?.shortDescription?.trim().length) {
            shortDescriptionRef.current.value = data.shortDescription;
          }

          if (data?.rawHTML?.trim().length) {
            rawHTMLRef.current.value = data.rawHTML;
          }
        } else {
          sessionIdRef.current = v4();

          const url = new URL(window.location.href);
          url.searchParams.set("sessionId", sessionIdRef.current);

          window.history.replaceState(null, "", url.toString());
        }
      }
    })();
  }, []);

  const createArticle = async () => {
    if (!game) {
      return;
    }

    if (galleryImages.images[0].current?.key == null || galleryImages.images[0].current?.gradient === null) {
      return;
    }

    if (titleInputRef.current.value.trim().length === 0) {
      return;
    }

    if (shortDescriptionRef.current.value.trim().length === 0) {
      return;
    }

    if (rawHTMLRef.current.value.trim().length === 0) {
      return;
    }

    setArticleLoading(true);

    const imagesOutput = await galleryImages.getImageResults({ srcResultType: "final" });

    try {
      await ArticleService.create({
        title: titleInputRef.current.value,
        shortDescription: shortDescriptionRef.current.value,
        rawHtml: rawHTMLRef.current.value,
        cover: {
          key: imagesOutput[0].key,
          gradient: imagesOutput[0].gradient,
        },
        readTime: calculateReadTime(rawHTMLRef.current.value),
        type: ArticleType.News,
        belongsTo: {
          type: ArticleBelongingType.Game,
          id: game._id,
        },
        attachments: [],
      });

      toast({
        title: "Article was created successful",
        status: "success",
      });
    } catch (err) {
      toast({
        title: "Article was not created",
        status: "error",
      });
    } finally {
      setArticleLoading(false);
    }
  };

  const putInSessionStore = (key: "title" | "shortDescription" | "rawHTML", value: string) => {
    console.log("sessionIdRef: ", sessionIdRef.current);
    const existed = sessionStorage.getItem(sessionIdRef.current);

    if (!existed) {
      sessionStorage.setItem(sessionIdRef.current, JSON.stringify({ [key]: value }));
      return;
    }

    const existedDataObj = JSON.parse(existed) as object;

    sessionStorage.setItem(
      sessionIdRef.current,
      JSON.stringify({
        ...existedDataObj,
        [key]: value,
      })
    );
  };

  return (
    <Box>
      <Box>Create Article Page</Box>
      {gameIdIsNotValid && (
        <Box>!!!Game ID in URL params is not valid or not exist, please back to game page and try again</Box>
      )}
      {gameLoading && <Box>!!!Please wait we are loading required data</Box>}
      {game === null && <Box>!!!No Game provided</Box>}

      <VStack alignItems={"start"}>
        <FormControl isInvalid={false}>
          <FormLabel>Title</FormLabel>
          <Input ref={titleInputRef as any} type="text" onChange={(e) => putInSessionStore("title", e.target.value)} />
        </FormControl>
        <Image ratio={{ size: 20, x: 16, y: 9 }} config={galleryImages.images[0]} updater={galleryImages.updater} />
        <FormControl isInvalid={false}>
          <FormLabel>Short Description</FormLabel>
          <Textarea
            ref={shortDescriptionRef as any}
            rows={6}
            onChange={(e) => putInSessionStore("shortDescription", e.target.value)}
          />
        </FormControl>
        <FormControl isInvalid={false}>
          <FormLabel>Raw HTML</FormLabel>
          <Textarea ref={rawHTMLRef as any} rows={15} onChange={(e) => putInSessionStore("rawHTML", e.target.value)} />
        </FormControl>
        <Button variant={"outline"} colorScheme={"green"} isLoading={articleLoading} onClick={createArticle}>
          Create
        </Button>
      </VStack>
    </Box>
  );
};
