import { Button, Card, CardBody, CardFooter, HStack, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { Store } from "../../../api/stores/store.model";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { storeService } from "../../../api/stores/store.service";
import { GenericFormModal } from "../../../components/GenericFormModal/GenericFormModal";
import * as yup from "yup";
import { GenericConfirmModal } from "../../../components/GenericConfirmModal/GenericConfirmModal";
import { StoreName } from "../../../api/stores/store-names.enum";

type Props = {
  store: Store;
  onRemoveSuccessfully: () => void;
  onRenameSuccessfully: () => void;
};

export const StoreCardItem = (props: Props) => {
  const toast = useToast();

  const renameModalDisclosure = useDisclosure();
  const removeModalDisclosure = useDisclosure();

  const [renameLoading, setRenameLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const rename = async ({ name, url }: Record<string, string>) => {
    const isStoreName = (name: string): name is StoreName => {
      if (Object.values(StoreName).includes(name as unknown as StoreName)) {
        return true;
      }

      return false;
    };

    if (!isStoreName(name)) {
      toast({
        title: "Store name is not in supported store list",
        status: "error",
      });
      return;
    }

    setRenameLoading(true);

    try {
      await storeService.update(props.store._id, {
        name,
        url,
      });
      props.onRenameSuccessfully();
      toast({
        title: "Step has been renamed",
        status: "success",
      });
      renameModalDisclosure.onClose();
    } catch (err) {
      toast({
        title: "Step has not been renamed",
        status: "error",
      });
      console.log(err);
    }

    setRenameLoading(false);
  };

  const remove = async () => {
    setRemoveLoading(true);

    try {
      await storeService.remove(props.store._id);
      toast({
        title: "Step has been removed",
        status: "success",
      });
      props.onRemoveSuccessfully();
      removeModalDisclosure.onClose();
    } catch (err) {
      toast({
        title: "Step has not been removed",
        status: "error",
      });
      console.log(err);
    }

    setRemoveLoading(false);
  };

  return (
    <Card>
      <CardBody>
        <Text fontWeight="medium">{props.store.name}</Text>
      </CardBody>
      <CardFooter>
        <HStack spacing={2}>
          <Button
            leftIcon={<EditIcon />}
            variant="outline"
            isLoading={renameLoading}
            disabled={removeLoading}
            onClick={renameModalDisclosure.onOpen}
          >
            Rename
          </Button>
          <Button
            leftIcon={<DeleteIcon />}
            colorScheme="red"
            isLoading={removeLoading}
            disabled={renameLoading}
            onClick={removeModalDisclosure.onOpen}
          >
            Remove
          </Button>
        </HStack>
      </CardFooter>
      <GenericConfirmModal
        title={"Are you sure you want to remove store?"}
        isOpen={removeModalDisclosure.isOpen}
        onClose={removeModalDisclosure.onClose}
        actionText={"Remove"}
        actionLoading={removeLoading}
        onAction={remove}
        type={"remove"}
      />
      <GenericFormModal
        title={"Rename Store"}
        isOpen={renameModalDisclosure.isOpen}
        onClose={renameModalDisclosure.onClose}
        actionLoading={renameLoading}
        onSubmit={rename}
        controls={[
          {
            name: "name",
            label: "Name",
            defaultValue: props.store.name,
          },
          {
            name: "url",
            label: "Url",
            defaultValue: props.store.url,
          },
        ]}
        controlsValidation={yup.object().shape({
          name: yup.string().required(),
          url: yup.string().required(),
        })}
      />
    </Card>
  );
};
