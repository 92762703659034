/**
 * @description
 * The resulted format should be yyyy-mm-dd
 */
export const convertUnixIntoMonthCalendarFormat = (unix: number) => {
  const date = new Date(unix);

  let month = date.getUTCMonth() + 1 + '';
  let day = date.getUTCDate() + '';

  if (month.length === 1) {
    month = '0' + month;
  }

  if (day.length === 1) {
    day = '0' + day;
  }

  return `${date.getUTCFullYear()}-${month}-${day}`;
}