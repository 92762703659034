import {
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
} from "@chakra-ui/react";
import { GameBuyLinkCreateModalIconCheckBox } from "./componenets/GameBuyLinkCreateModalIconCheckBox";
import { ReactComponent as IconPlatformWindows } from "./../../../../assets/icons/icon_platform_windows.svg";
import { ReactComponent as IconPlatformPlaystation4 } from "./../../../../assets/icons/icon_platform_playstation-4.svg";
import { ReactComponent as IconPlatformPlaystation5 } from "./../../../../assets/icons/icon_platform_playstation-5.svg";
import { ReactComponent as IconPlatformXboxOne } from "./../../../../assets/icons/icon_platform_xbox-one.svg";
import { ReactComponent as IconPlatformXboxXs } from "./../../../../assets/icons/icon_platform_xbox-xs.svg";
import { ReactComponent as IconPlatformAndStoreNintendo } from "./../../../../assets/icons/icon_store_nintendo.svg";
import { ReactComponent as IconStorePlaystation } from "./../../../../assets/icons/icon_store_playstation.svg";
import { ReactComponent as IconStoreXbox } from "./../../../../assets/icons/icon_store_xbox.svg";
import { ReactComponent as IconStoreEpicGames } from "./../../../../assets/icons/icon_store_epic-games.svg";
import { ReactComponent as IconStoreMicrosoft } from "./../../../../assets/icons/icon_store_microsoft.svg";
import { ReactComponent as IconStoreSteam } from "./../../../../assets/icons/icon_store_steam.svg";
import { ReactComponent as IconStoreEA } from "./../../../../assets/icons/icon_store_ea.svg";
import { ReactComponent as IconStoreUbisoft } from "./../../../../assets/icons/icon_store_ubisoft.svg";
import { ReactComponent as IconStoreBattleNet } from "./../../../../assets/icons/icon_store_battle-net.svg";
import React, { useEffect, useRef, useState } from "react";
import { Platform } from "../../../../api/platforms/platform.model";
import { Store } from "../../../../api/stores/store.model";
import { PlatformName } from "../../../../api/platforms/platform-names.enum";
import { filterPlatformByUrlV2 } from "../../../../utils/platforms/filter-platform-by-url-v2";
import { StoreName } from "../../../../api/stores/store-names.enum";
import { filterStoresByPlatformV2 } from "../../../../utils/stores/filter-stores-by-platform-v2";
import { propEq } from "ramda";
import { filterStoresByUrlV2 } from "../../../../utils/stores/filter-stores-by-url-v2";
import { BuyLink } from "../../../../api/shared/buyLink.model";

const getOnlyPlatformsWithStores = (data: {
  url: string;
  platforms: Platform[];
  stores: Store[];
  buyLinks: BuyLink[];
}) => {
  const storesByUrl = filterStoresByUrlV2(data.url, data.stores);
  const platformsByUrl = filterPlatformByUrlV2(data.url, data.platforms);

  const buyLinkPlatformIdStoreIdMapping = data.buyLinks.map((buyLink) => `${buyLink.platform}_${buyLink.store}`);

  return platformsByUrl.reduce<{ platform: Platform; stores: Store[] }[]>((result, platform) => {
    const storesByPlatform = filterStoresByPlatformV2(platform, storesByUrl);
    const storesByBuyLink = storesByPlatform.filter(
      (store) => !buyLinkPlatformIdStoreIdMapping.includes(`${platform._id}_${store._id}`)
    );

    if (!storesByBuyLink.length) {
      return result;
    }

    result.push({
      platform,
      stores: storesByBuyLink,
    });

    return result;
  }, []);
};

const getBuyLinkFormValidation = (options: {
  url: string;
  selectedPlatform: Platform | null;
  selectedStore: Store | null;
}) => {
  const urlIsValid = (() => {
    try {
      new URL(options.url);
      return true;
    } catch (err) {
      return false;
    }
  })();

  const platformIsValid = !!options.selectedPlatform;
  const storeIsValid = !!options.selectedStore;

  const formIsValid = urlIsValid && platformIsValid && storeIsValid;

  return {
    isValid: formIsValid,
    errors: {
      url: urlIsValid ? null : "URL is not valid",
      platform: platformIsValid ? null : "Platform is not valid",
      store: storeIsValid ? null : "Store is not valid",
    },
  };
};

enum Mode {
  Create = "Create",
  Edit = "Edit",
}

type Props = {
  mode: Mode;

  buyLinks: BuyLink[];
  platforms: Platform[];
  stores: Store[];

  resetId?: string | number;

  isOpen: boolean;
  onClose: () => void;
  onAction: (data: { url: string; platformId: string; storeId: string }) => void;
  actionLoading: boolean;
};

export const GameBuyLinkModal = (props: Props) => {
  const [url, setUrl] = useState<string>("");
  const [urlInputInFocus, setUrlInputInFocus] = useState<boolean>(false);

  const [selectedPlatform, setSelectedPlatform] = useState<null | Platform>(null);
  const [selectedStore, setSelectedStore] = useState<null | Store>(null);

  const [availablePlatforms, setAvailablePlatforms] = useState<Platform[]>([]);
  const [availableStores, setAvailableStores] = useState<Store[]>([]);

  useEffect(() => {
    if (props.isOpen) {
      return;
    }

    resetUrl();
    resetStoresAndPlatformsStates();
  }, [props.isOpen]);

  const initialResetId = useRef(props.resetId);

  useEffect(() => {
    // Prevent component from unnecessary initial reset
    if (initialResetId.current === props.resetId) {
      return;
    }

    resetUrl();
    resetStoresAndPlatformsStates();
  }, [props.resetId]);

  const formValidationData = getBuyLinkFormValidation({
    url,
    selectedPlatform,
    selectedStore,
  });

  const resetStoresAndPlatformsStates = () => {
    setAvailablePlatforms([]);
    setAvailableStores([]);
    setSelectedPlatform(null);
    setSelectedStore(null);
  };

  const resetUrl = () => {
    setUrl("");
  };

  const updateURL = (nextUrl: string) => {
    setUrl(nextUrl);

    const onlyPlatformsWithStores = getOnlyPlatformsWithStores({
      url: nextUrl,
      buyLinks: props.buyLinks,
      stores: props.stores,
      platforms: props.platforms,
    });

    const { availablePlatforms, availableStores } = onlyPlatformsWithStores.reduce<{
      availablePlatforms: Platform[];
      availableStores: Store[];
    }>(
      (result, current) => {
        result.availablePlatforms.push(current.platform);
        result.availableStores.push(...current.stores);
        return result;
      },
      {
        availablePlatforms: [],
        availableStores: [],
      }
    );

    if (!availablePlatforms.length || !availableStores.length) {
      resetStoresAndPlatformsStates();
      return;
    }

    setAvailableStores(availableStores);
    setAvailablePlatforms(availablePlatforms);

    setSelectedStore(availableStores[0]);
    setSelectedPlatform(availablePlatforms[0]);
  };

  const checkPlatformIsDisabled = (platformName: PlatformName) => {
    return !availablePlatforms.find(({ name }) => name === platformName);
  };

  const updateSelectedPlatform = (platformName: string) => {
    const nextPlatform = props.platforms.find(propEq(platformName, "name"));

    if (!nextPlatform) {
      return;
    }

    const storesByPlatform = filterStoresByPlatformV2(nextPlatform, availableStores);

    setSelectedPlatform(nextPlatform);
    setAvailableStores(storesByPlatform);

    if (storesByPlatform.length > 0) {
      setSelectedStore(storesByPlatform[0]);
    }
  };

  const checkStoreIsActive = (storeName: StoreName) => {
    if (!selectedStore) {
      return false;
    }

    return selectedStore.name === storeName;
  };

  const checkPlatformIsActive = (platformName: PlatformName) => {
    if (!selectedPlatform) {
      return false;
    }

    return platformName === selectedPlatform.name;
  };

  const checkStoreIsDisabled = (storeName: StoreName) => {
    return !availableStores.find(({ name }) => name === storeName);
  };

  const selectStore = (storeName: string) => {
    const store = props.stores.find((value) => value.name === storeName);

    if (!store) {
      return;
    }

    setSelectedStore(store);
  };

  const actionHandler = () => {
    if (!formValidationData.isValid) {
      return;
    }

    if (!selectedPlatform || !selectedStore) {
      return;
    }

    props.onAction({
      url,
      platformId: selectedPlatform._id,
      storeId: selectedStore._id,
    });
  };

  return (
    <Box>
      <Modal isOpen={props.isOpen} onClose={props.onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Buy Link</ModalHeader>
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel htmlFor={""}>URL</FormLabel>
                <div style={{ height: "40px" }} />
                <Input
                  name={"url"}
                  value={url}
                  onChange={(e) => updateURL(e.target.value)}
                  position={"absolute"}
                  bottom={0}
                  background={"#fff"}
                  left={"50%"}
                  {...(urlInputInFocus
                    ? {
                        width: "750px",
                        maxWidth: "initial",
                        transform: "translateX(-50%) scale(1)",
                        boxShadow: "0 0 4px 1px #6060607d",
                      }
                    : {
                        transform: "translateX(-50%) scale(1)",
                      })}
                  onFocus={() => setUrlInputInFocus(true)}
                  onBlur={() => setUrlInputInFocus(false)}
                />
              </FormControl>
              <FormControl>
                <FormLabel mb={4}>Platform</FormLabel>
                <HStack spacing={2} alignItems={"stretch"}>
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={updateSelectedPlatform}
                    id={PlatformName.Windows}
                    isActive={checkPlatformIsActive(PlatformName.Windows)}
                    isDisabled={checkPlatformIsDisabled(PlatformName.Windows)}
                    icon={<IconPlatformWindows />}
                  />
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={updateSelectedPlatform}
                    id={PlatformName.Playstation4}
                    isActive={checkPlatformIsActive(PlatformName.Playstation4)}
                    isDisabled={checkPlatformIsDisabled(PlatformName.Playstation4)}
                    icon={<IconPlatformPlaystation4 />}
                  />
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={updateSelectedPlatform}
                    id={PlatformName.Playstation5}
                    isActive={checkPlatformIsActive(PlatformName.Playstation5)}
                    isDisabled={checkPlatformIsDisabled(PlatformName.Playstation5)}
                    icon={<IconPlatformPlaystation5 />}
                  />
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={updateSelectedPlatform}
                    id={PlatformName.XboxOne}
                    isActive={checkPlatformIsActive(PlatformName.XboxOne)}
                    isDisabled={checkPlatformIsDisabled(PlatformName.XboxOne)}
                    icon={<IconPlatformXboxOne />}
                  />
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={updateSelectedPlatform}
                    id={PlatformName.XboxXS}
                    isActive={checkPlatformIsActive(PlatformName.XboxXS)}
                    isDisabled={checkPlatformIsDisabled(PlatformName.XboxXS)}
                    icon={<IconPlatformXboxXs />}
                  />
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={updateSelectedPlatform}
                    id={PlatformName.NintendoSwitch}
                    isActive={checkPlatformIsActive(PlatformName.NintendoSwitch)}
                    isDisabled={checkPlatformIsDisabled(PlatformName.NintendoSwitch)}
                    icon={<IconPlatformAndStoreNintendo />}
                  />
                </HStack>
              </FormControl>
              <FormControl>
                <FormLabel mb={4}>Store</FormLabel>
                <HStack spacing={2} alignItems={"stretch"} flexWrap={"wrap"}>
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={selectStore}
                    id={StoreName.Playstation}
                    isActive={checkStoreIsActive(StoreName.Playstation)}
                    isDisabled={checkStoreIsDisabled(StoreName.Playstation)}
                    icon={<IconStorePlaystation />}
                  />
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={selectStore}
                    id={StoreName.EpicGames}
                    isActive={checkStoreIsActive(StoreName.EpicGames)}
                    isDisabled={checkStoreIsDisabled(StoreName.EpicGames)}
                    icon={<IconStoreEpicGames />}
                  />
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={selectStore}
                    id={StoreName.Microsoft}
                    isActive={checkStoreIsActive(StoreName.Microsoft)}
                    isDisabled={checkStoreIsDisabled(StoreName.Microsoft)}
                    icon={<IconStoreMicrosoft />}
                  />
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={selectStore}
                    id={StoreName.Xbox}
                    isActive={checkStoreIsActive(StoreName.Xbox)}
                    isDisabled={checkStoreIsDisabled(StoreName.Xbox)}
                    icon={<IconStoreXbox />}
                  />
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={selectStore}
                    id={StoreName.Nintendo}
                    isActive={checkStoreIsActive(StoreName.Nintendo)}
                    isDisabled={checkStoreIsDisabled(StoreName.Nintendo)}
                    icon={<IconPlatformAndStoreNintendo />}
                  />
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={selectStore}
                    id={StoreName.Steam}
                    isActive={checkStoreIsActive(StoreName.Steam)}
                    isDisabled={checkStoreIsDisabled(StoreName.Steam)}
                    icon={<IconStoreSteam />}
                  />
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={selectStore}
                    id={StoreName.Ubisoft}
                    isActive={checkStoreIsActive(StoreName.Ubisoft)}
                    isDisabled={checkStoreIsDisabled(StoreName.Ubisoft)}
                    icon={<IconStoreUbisoft />}
                  />
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={selectStore}
                    id={StoreName.BattleNet}
                    isActive={checkStoreIsActive(StoreName.BattleNet)}
                    isDisabled={checkStoreIsDisabled(StoreName.BattleNet)}
                    icon={<IconStoreBattleNet />}
                  />
                  <GameBuyLinkCreateModalIconCheckBox
                    onClick={selectStore}
                    id={StoreName.ElectronicArts}
                    isActive={checkStoreIsActive(StoreName.ElectronicArts)}
                    isDisabled={checkStoreIsDisabled(StoreName.ElectronicArts)}
                    icon={<IconStoreEA />}
                  />
                </HStack>
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <HStack spacing={2}>
              <Button onClick={props.onClose}>Cancel</Button>
              <Button
                variant={"outline"}
                colorScheme={"green"}
                isLoading={props.actionLoading}
                onClick={actionHandler}
                isDisabled={!formValidationData.isValid}
              >
                {props.mode === Mode.Create && "Create"}
                {props.mode === Mode.Edit && "Update"}
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

GameBuyLinkModal.mode = Mode;

export type GameBuyLinkModal = {
  Props: Props;
};
