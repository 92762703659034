import { Button, Card, CardBody, CardFooter, HStack, Text, useDisclosure, useToast } from "@chakra-ui/react";
import { useState } from "react";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { GenericConfirmModal } from "../../../components/GenericConfirmModal/GenericConfirmModal";
import { GenericFormModal } from "../../../components/GenericFormModal/GenericFormModal";
import * as yup from "yup";
import { Platform } from "../../../api/platforms/platform.model";
import { platformService } from "../../../api/platforms/platform.service";
import { PlatformName } from "../../../api/platforms/platform-names.enum";

type Props = {
  platform: Platform;
  onRemoveSuccessfully: () => void;
  onRenameSuccessfully: () => void;
};

export const PlatformCardItem = (props: Props) => {
  const toast = useToast();

  const renameModalDisclosure = useDisclosure();
  const removeModalDisclosure = useDisclosure();

  const [renameLoading, setRenameLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const rename = async ({ name }: Record<string, string>) => {
    setRenameLoading(true);

    try {
      await platformService.update(props.platform._id, { name: name as PlatformName });

      props.onRenameSuccessfully();
      toast({
        title: "Platform has been renamed",
        status: "success",
      });
      renameModalDisclosure.onClose();
    } catch (err) {
      toast({
        title: "Platform has not been renamed",
        status: "error",
      });
      console.log(err);
    }

    setRenameLoading(false);
  };

  const remove = async () => {
    setRemoveLoading(true);

    try {
      await platformService.remove(props.platform._id);
      toast({
        title: "Platform has been removed",
        status: "success",
      });
      props.onRemoveSuccessfully();
      removeModalDisclosure.onClose();
    } catch (err) {
      toast({
        title: "Platform has not been removed",
        status: "error",
      });
      console.log(err);
    }

    setRemoveLoading(false);
  };

  return (
    <Card>
      <CardBody>
        <Text fontWeight="medium">{props.platform.name}</Text>
      </CardBody>
      <CardFooter>
        <HStack spacing={2}>
          <Button
            leftIcon={<EditIcon />}
            variant="outline"
            isLoading={renameLoading}
            disabled={removeLoading}
            onClick={renameModalDisclosure.onOpen}
          >
            Rename
          </Button>
          <Button
            leftIcon={<DeleteIcon />}
            colorScheme="red"
            isLoading={removeLoading}
            disabled={renameLoading}
            onClick={removeModalDisclosure.onOpen}
          >
            Remove
          </Button>
        </HStack>
      </CardFooter>
      <GenericConfirmModal
        title={"Are you sure you want to remove platform?"}
        isOpen={removeModalDisclosure.isOpen}
        onClose={removeModalDisclosure.onClose}
        actionText={"Remove"}
        actionLoading={removeLoading}
        onAction={remove}
        type={"remove"}
      />
      <GenericFormModal
        title={"Rename Platform"}
        isOpen={renameModalDisclosure.isOpen}
        onClose={renameModalDisclosure.onClose}
        actionLoading={renameLoading}
        onSubmit={rename}
        controls={[
          {
            name: "name",
            label: "Name",
            defaultValue: props.platform.name,
          },
        ]}
        controlsValidation={yup.object().shape({
          name: yup.string().required(),
        })}
      />
    </Card>
  );
};
