import React, { ForwardedRef, forwardRef, useImperativeHandle } from "react";
import { ArticleAttachment } from "../../../../api/articles/article.model";
import { Box, Button, Flex } from "@chakra-ui/react";
import { ImageOutput, useImages } from "../../../../components/Image/useImages";
import { Image } from "../../../../components/Image/Image";
import { DownloadIcon, PlusSquareIcon } from "@chakra-ui/icons";
import { v4 } from "uuid";

type Props = {
  attachments: ArticleAttachment[];
};

type FRefController = {
  getImages: () => Promise<ImageOutput[]>;
};

export const ArticleManageAttachments = forwardRef((props: Props, ref: ForwardedRef<FRefController>) => {
  const imagesController = useImages({
    createDefaultIfNotExists: true,
    images: props.attachments.map((attachment) => ({
      key: attachment.key,
      uniqId: attachment._id,
      gradient: attachment.gradient,
    })),
  });

  useImperativeHandle(ref, () => ({
    getImages: async () => {
      return imagesController.getImageResults({ srcResultType: "final" });
    },
  }));

  const containsAttachmentsToUpload = imagesController.images.some((image) => {
    return image.current.file !== null;
  });

  const uploadAttachments = async () => {
    const imageOutputs = await imagesController.getImageResults({ srcResultType: "final" });

    imagesController.resetImages(
      imageOutputs.map((image) => ({ key: image.key, uniqId: image._id || v4(), gradient: image.gradient }))
    );
  };

  return (
    <Box>
      <Flex gap={4}>
        <Button
          colorScheme={"linkedin"}
          size={"sm"}
          leftIcon={<PlusSquareIcon />}
          onClick={() => {
            imagesController.addImage();
          }}
        >
          Add attachment
        </Button>
        <Button
          disabled={!containsAttachmentsToUpload}
          variant={"outline"}
          colorScheme={"green"}
          size={"sm"}
          leftIcon={<DownloadIcon style={{ transform: "rotate(180deg) " }} />}
          onClick={uploadAttachments}
        >
          Upload attachments
        </Button>
      </Flex>
      <Flex wrap={"wrap"} mt={2} gap={4}>
        {imagesController.images.map((image, index) => (
          <Box
            position={"relative"}
            key={image.uniqId}
            borderWidth={"1px"}
            borderColor={"gray.200"}
            py={2}
            px={1}
            borderRadius={"10px"}
          >
            <Image
              ratio={{ size: 20, x: 16, y: 9 }}
              config={image}
              updater={imagesController.updater}
              showLink
              copyLinkInterceptor={(str) => {
                return `<div data-image><img src="${str}"/></div>`;
              }}
            />
          </Box>
        ))}
      </Flex>
    </Box>
  );
});

export type ArticleManageAttachments = {
  Props: Props;
  FRefController: FRefController;
};
