import { useMemo } from "react";

export const useObjArrByKey = <T extends { _id: string }>(arrOfObjs: T[]): Record<string, T> => {
  const arrOfObjsByKey = useMemo(() => {
    return arrOfObjs.reduce((obj, element) => {
      return {
        ...obj,
        [element._id]: element,
      };
    }, {}) as Record<string, T>;
  }, [arrOfObjs]);

  return arrOfObjsByKey;
};
