import { Button, ChakraProps, Flex } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { genName } from "../../../../utils/components/genName";
import { Link } from "react-router-dom";

type Props = {
  style?: ChakraProps;
};

export const GamesAppConfig = (props: Props) => {
  return (
    <Flex {...genName(GamesAppConfig)}>
      <Link to={"/app-config/crossplay/games-sort-order"}>
        <Button {...props.style} rightIcon={<ArrowForwardIcon />} colorScheme="teal" variant="outline" size={"sm"}>
          Crossplay Sort Order
        </Button>
      </Link>
    </Flex>
  );
};
