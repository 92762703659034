import axios, { AxiosResponse } from "axios";
import { endpoints } from "./endpoints";

export class AuthService {
  static login = async (data: { name: string; password: string }): Promise<AxiosResponse<string>> => {
    return axios.post(endpoints.login.insert(), {
      name: data.name,
      password: data.password,
    });
  };
}
