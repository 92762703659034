import { StoreName } from "../../api/stores/store-names.enum";
import { PlatformName } from "../../api/platforms/platform-names.enum";

export const platformsByStoreConfig: Record<StoreName, PlatformName[]> = {
  [StoreName.Playstation]: [PlatformName.Playstation4, PlatformName.Playstation5],
  [StoreName.Xbox]: [PlatformName.XboxOne, PlatformName.XboxXS, PlatformName.Windows],
  [StoreName.Nintendo]: [PlatformName.NintendoSwitch],
  [StoreName.Steam]: [PlatformName.Windows],
  [StoreName.BattleNet]: [PlatformName.Windows],
  [StoreName.EpicGames]: [PlatformName.Windows],
  [StoreName.Ubisoft]: [PlatformName.Windows],
  [StoreName.Microsoft]: [PlatformName.Windows],
  [StoreName.ElectronicArts]: [PlatformName.Windows],
};
