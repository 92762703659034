import { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";

export enum ConnectionStatus {
  INITIAL = "Initial",
  CONNECTING = "Connecting",
  CONNECTED = "Connected",
  DISCONNECTED = "Disconnected",
}

enum AdminEventType {
  Ping = "Ping",
  Pong = "Pong",
  Data = "Data",
}

enum CopilotEventType {
  Pong = "Pong",
  Ping = "Ping",
  RequestData = "RequestData",
}

type EventData<TEventType, TDataType> = {
  type: TEventType;
  hash: string;
  payload: TDataType;
};

type PongEventData = EventData<CopilotEventType.Pong, {}>;
type PingEventData = EventData<CopilotEventType.Ping, {}>;
type RequestDataEventData = EventData<CopilotEventType.RequestData, {}>;

type Options = {
  entity: "game" | "article";
};

export const usePreview = (options: Options) => {
  const [connectionStatus, setConnectionStatus] = useState<ConnectionStatus>(ConnectionStatus.INITIAL);

  const dataRef = useRef<unknown>(null);

  const previewPageRef = useRef<null | Window>(null);
  const previewPageHashRef = useRef<string>(uuid());

  const copilotUrl = `${process.env.REACT_APP_COPILOT_ORIGIN}/admin-preview/${options.entity}/${previewPageHashRef.current}`;

  const closeMonitoringIntervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

  const pingEventHasBeenOccurredRef = useRef<boolean>(false);

  const sendPing = (page: Window) => page.postMessage({ type: AdminEventType.Ping }, copilotUrl);
  const sendPong = (page: Window) => page.postMessage({ type: AdminEventType.Pong }, copilotUrl);

  const handleRequestDataEvent = (event: MessageEvent<RequestDataEventData>) => {
    if (!event.source) {
      return;
    }

    event.source!.postMessage({ type: AdminEventType.Data, data: dataRef.current }, copilotUrl as any);
  };

  const handlePongEvent = (event: MessageEvent<PongEventData>) => {};

  const handlePingEvent = (event: MessageEvent<PingEventData>) => {
    if (!pingEventHasBeenOccurredRef.current) {
      pingEventHasBeenOccurredRef.current = true;
      setConnectionStatus(ConnectionStatus.CONNECTED);
    }

    if (previewPageRef.current) {
      sendPong(previewPageRef.current);
    }
  };

  const handleMessage = (
    e: MessageEvent<PongEventData> | MessageEvent<PingEventData> | MessageEvent<RequestDataEventData>
  ) => {
    if (e.origin !== process.env.REACT_APP_COPILOT_ORIGIN) {
      return;
    }

    if (e.data.hash !== previewPageHashRef.current) {
      return;
    }

    if (e.data.type === CopilotEventType.Pong) {
      handlePongEvent(e as MessageEvent<PongEventData>);
    } else if (e.data.type === CopilotEventType.Ping) {
      handlePingEvent(e as MessageEvent<PingEventData>);
    } else if (e.data.type === CopilotEventType.RequestData) {
      handleRequestDataEvent(e as MessageEvent<RequestDataEventData>);
    }
  };

  useEffect(() => {
    const listener = (e: MessageEvent<PongEventData>) => handleMessage(e);

    window.addEventListener("message", listener);

    return () => {
      window.removeEventListener("message", listener);
    };
  }, []);

  const disconnect = () => {
    setConnectionStatus(ConnectionStatus.DISCONNECTED);

    if (previewPageRef.current) {
      previewPageRef.current.close();
    }

    if (closeMonitoringIntervalRef.current) {
      clearInterval(closeMonitoringIntervalRef.current);
    }

    closeMonitoringIntervalRef.current = null;
    pingEventHasBeenOccurredRef.current = false;

    previewPageRef.current = null;
  };

  const monitorPreviewPageCloseStatus = () => {
    closeMonitoringIntervalRef.current = setInterval(() => {
      if (!previewPageRef.current) {
        return;
      }

      if (previewPageRef.current.closed) {
        disconnect();
      }
    }, 10);
  };

  const start = () => {
    if (![ConnectionStatus.INITIAL, ConnectionStatus.DISCONNECTED].includes(connectionStatus)) {
      return;
    }

    previewPageRef.current = window.open(copilotUrl, "_blank");

    monitorPreviewPageCloseStatus();

    setConnectionStatus(ConnectionStatus.CONNECTING);
  };

  const updateEntity = (entity: unknown) => {
    dataRef.current = entity;

    if (!previewPageRef.current) {
      return;
    }

    previewPageRef.current?.postMessage({ type: AdminEventType.Data, data: dataRef.current }, copilotUrl);
  };

  return {
    connectionStatus: {
      Initial: connectionStatus === ConnectionStatus.INITIAL,
      Connecting: connectionStatus === ConnectionStatus.CONNECTING,
      Connected: connectionStatus === ConnectionStatus.CONNECTED,
      Disconnected: connectionStatus === ConnectionStatus.DISCONNECTED,
    },

    disconnect,
    start,
    updateEntity,
  };
};
