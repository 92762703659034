import { useFormik } from "formik";
import {
  ExternalControlProps,
  GenericFormV2AvailableControls,
  GenericFormV2ControlType,
  GenericFormV2SelectOption,
} from "./types";
import { FormikHelpers } from "formik/dist/types";
import { useState } from "react";

type Control<T extends Record<any, any>> = { name: keyof T } & GenericFormV2AvailableControls;

type Args<T extends Record<any, any>> = {
  initialValues: T;
  validationScheme?: any;
  controls: Control<T>[];
  onSubmit: (
    values: Record<keyof T, string>,
    formikHelpers: FormikHelpers<Record<keyof T, string>>
  ) => Promise<void> | void;
};

export const useGenericFormV2 = <T extends Record<any, any>>(options: Args<T>) => {
  const [controls, setControls] = useState<(typeof options)["controls"]>(options.controls);

  const formik = useFormik({
    ...(options.validationScheme ? { validationSchema: options.validationScheme } : {}),
    initialValues: options.initialValues,
    onSubmit: options.onSubmit,
  });

  const updateControlOptions = <Key extends keyof T>(field: Key, options: GenericFormV2SelectOption[]) => {
    setControls((prev) =>
      prev.map((elem) => {
        if (elem.name === field) {
          if (elem.type === GenericFormV2ControlType.Select) {
            return {
              ...elem,
              options,
            };
          }
        }

        return elem;
      })
    );
  };

  const enableField = (field: keyof T) => {
    setControls((prev) => prev.map((control) => (control.name === field ? { ...control, disabled: false } : control)));
  };

  const disableField = (field: keyof T) => {
    setControls((prev) => prev.map((control) => (control.name === field ? { ...control, disabled: true } : control)));
  };

  const updateExternalControlProps = (externalControlName: string, nextProps: ExternalControlProps) => {
    setControls((prev) =>
      prev.map((control) => {
        if (control.rightElement?.name === externalControlName) {
          return {
            ...control,
            rightElement: { ...control.rightElement, props: nextProps },
          };
        }

        return control;
      })
    );
  };

  return {
    formik,
    // prettier-ignore
    setFieldValue: (field: keyof T, value: any, shouldValidate?: boolean | undefined) => formik.setFieldValue(field as string, value, shouldValidate),
    updateExternalControlProps,
    updateControlOptions,
    enableField,
    disableField,
    values: formik.values,
    controls,
  };
};
