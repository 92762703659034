import { Badge, ChakraProps, Flex, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { genName } from "../../../../../../utils/components/genName";
import React from "react";
import { FilterCrossplayVerificationFilterValues } from "../../../../../../pages/GamesListPage/types/game-filter";

type Props = {
  status: FilterCrossplayVerificationFilterValues | "Any";
  updateStatus: (nextStatus: FilterCrossplayVerificationFilterValues | "Any") => void;
  style?: ChakraProps;
};

export const FilterCrossplayVerifiedStatus = (props: Props) => {
  return (
    <Flex {...(props.style ? props.style : {})} {...genName(FilterCrossplayVerifiedStatus)}>
      <Badge display={"flex"} alignItems={"center"} mr={2}>
        Crossplay Verification
      </Badge>
      <RadioGroup onChange={(v) => props.updateStatus(v as any)} value={props.status}>
        <Stack direction="row">
          {Object.values(FilterCrossplayVerificationFilterValues).map((value) => {
            return (
              <Radio key={value} value={value}>
                {value}
              </Radio>
            );
          })}
          <Radio value={"Any"}>Any</Radio>
        </Stack>
      </RadioGroup>
    </Flex>
  );
};
