import { Game } from "../api/games/game.model";

export enum CompletionStatus {
  Completed = "linkedin",
  Empty = "blackAlpha",
  Partial = "yellow",
}

export class GameCompletionService {
  static getCompletionStatus = (items: any[]): CompletionStatus => {
    const trueCount = items.reduce((prev, currentValue) => (!!currentValue ? prev + 1 : prev), 0);

    if (trueCount === 0) {
      return CompletionStatus.Empty;
    }

    if (trueCount === items.length) {
      return CompletionStatus.Completed;
    }

    return CompletionStatus.Partial;
  };

  static basicInfoIsCompleted = (game: Game): CompletionStatus => {
    return GameCompletionService.getCompletionStatus([
      game.name.trim().length,
      game.description.trim().length,
      game.releaseDate !== 0,
    ]);
  };

  static genresCompleted = (game: Game): CompletionStatus => {
    return GameCompletionService.getCompletionStatus([game.genres.length]);
  };

  static socialsCompleted = (game: Game): CompletionStatus => {
    return GameCompletionService.getCompletionStatus([game.socials.length]);
  };

  static newsCompleted = (game: Game): CompletionStatus => {
    return GameCompletionService.getCompletionStatus([game.news[0], game.news[1], game.news[2]]);
  };

  static SEOCompleted = (game: Game): CompletionStatus => {
    return GameCompletionService.getCompletionStatus([
      game.seo.crossplay.name?.trim().length,
      game.seo.crossplay.description?.trim().length,
      game.seo.crossplay.image?.trim().length,
    ]);
  };

  static buyLinksCompleted = ({ buyLinks }: Game): CompletionStatus => {
    return GameCompletionService.getCompletionStatus([buyLinks.length]);
  };

  static crossesCompleted = ({ crosses }: Game): CompletionStatus => {
    return GameCompletionService.getCompletionStatus([crosses.length]);
  };

  static imageCompleted = ({ image }: Game): CompletionStatus => {
    return GameCompletionService.getCompletionStatus([
      image.posterUrl?.length,
      image.posterGradient?.length,
      image.bannerUrl?.length,
      image.bannerGradient?.length,
    ]);
  };

  static galleryCompleted = ({ mediaGallery }: Game): CompletionStatus => {
    return GameCompletionService.getCompletionStatus([mediaGallery.length > 0, mediaGallery.length >= 7]);
  };

  static playModesCompleted = (game: Game): CompletionStatus => {
    return GameCompletionService.getCompletionStatus([game.playModes.length]);
  };
}
