import { Platform } from "../../api/platforms/platform.model";
import { storeRegularExpressionsConfig } from "../stores/store-regular-expressions.config";
import { StoreName } from "../../api/stores/store-names.enum";
import { platformsByStoreConfig } from "./platforms-by-store.config";

export const filterPlatformByUrlV2 = (url: string, platforms: Platform[]): Platform[] => {
  const entries = Object.entries(storeRegularExpressionsConfig) as unknown as Array<[StoreName, RegExp]>;

  for (const [storeName, regExp] of entries) {
    if (regExp.test(url)) {
      return platforms.filter((platform) => platformsByStoreConfig[storeName].includes(platform.name));
    }
  }

  return [];
};
