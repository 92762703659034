import { AbsoluteCenter, Box, Center, Flex, Text } from "@chakra-ui/react";
// @ts-ignore
import { ReactComponent as Logo } from "../../../assets/logo.svg";
// @ts-ignore
import { ReactComponent as LightBulb } from "../../../assets/light-bulb.svg";
import { Game } from "../../../api/games/game.model";
import {useEffect, useRef} from "react";

type Props = {
  seo: Omit<Game["seo"]["crossplay"], "keywords">;
  imageYPosition: number;
};

export const GameSeoOgImage = (props: Props) => {
  const imgRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    if (!imgRef.current) {
      return
    }

  }, [props.imageYPosition])

  return (
    <Box
      mt={0}
      position={"absolute"}
      top={"100%"}
      w={"1200px"}
      h={"630px"}
      bg={"linear-gradient(270deg, #373B44 0%, #4286F4 100%)"}
    >
      <Center pt={"32px"}>
        <Logo />
      </Center>
      <AbsoluteCenter
        w={"983px"}
        h={"442px"}
        boxShadow={"0px 4px 4px rgba(0, 0, 0, 0.09)"}
        borderRadius={"12px"}
        mt={"20px"}
      >
        <Flex
          direction={"column"}
          w={"100%"}
          h={"100%"}
          borderRadius={"12px"}
          overflow={"hidden"}
          background={`linear-gradient(270deg, rgba(30, 32, 38, 0.8) 0%, rgba(23, 57, 113, 0.8) 100%)`}
          backgroundSize={"cover"}
          backgroundPosition={"center"}
        >
          <img
            src={props.seo.image as string}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "absolute",
              zIndex: "-1",
              borderRadius: "12px",
              objectPosition: `0px ${props.imageYPosition}px`,
            }}
          />
          <Text
            fontFamily={"Ubuntu"}
            fontWeight={"700"}
            fontSize={"48px"}
            lineHeight={"1"}
            marginTop={"auto"}
            marginBottom={"60px"}
            textAlign={"center"}
            color={"#ffffff"}
          >
            {props.seo.name}
          </Text>
          <Box mb={"37px"}>
            <Center mb={"8px"}>
              <LightBulb />
            </Center>
            <Text
              fontFamily={"Comfortaa"}
              fontWeight={"700"}
              fontSize={"24px"}
              lineHeight={"141%"}
              textAlign={"center"}
              color={"#ffffff"}
            >
              This game has a crossplay.
              <br />
              Open link and check between which platform you can play
            </Text>
          </Box>
        </Flex>
      </AbsoluteCenter>
    </Box>
  );
};
