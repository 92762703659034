import { useCallback, useEffect, useRef, useState } from "react";
import { Game } from "../../api/games/game.model";
import { gameService } from "../../api/games/game.service";
import { PagRes } from "../../api/shared/pagRes.model";
import { GameFilter } from "../../pages/GamesListPage/types/game-filter";
import { PagReq } from "../../api/shared/pagReq.model";
import { useNavigate } from "react-router-dom";
import { createQueryForGameFilter } from "../../pages/GamesListPage/utils/create-query-for-game-filter";
import { addPaginationToQuery } from "../../utils/pagination/add-pagination-to-query";
import { useLocation } from "react-router";

const ITEMS_PER_PAGE = 20;

type Input = {
  initialFilter: GameFilter;
  initialPagination: PagReq | null;
  addQueryParams?: boolean;
};

type PaginationData = {
  take?: number;
  skip: number;
};

export const useGamesSearchWidget = (options: Input) => {
  const { initialFilter, initialPagination, addQueryParams = true } = options;
  const navigate = useNavigate();
  const location = useLocation();

  const latestFilter = useRef<GameFilter>(initialFilter);

  const [data, setData] = useState<PagRes<Game>>({
    take: ITEMS_PER_PAGE,
    skip: 0,
    entities: [],
    count: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [hasBeenLoaded, setHasBeenLoaded] = useState<boolean>(false);

  const load = async (
    loadOptions?: {
      filter?: GameFilter;
      pagReq?: PaginationData;
      resetPagination?: boolean;
    },
    onSuccess?: () => void
  ) => {
    const pagination: Required<PaginationData> = loadOptions?.resetPagination
      ? { take: ITEMS_PER_PAGE, skip: 0 }
      : loadOptions?.pagReq
      ? { take: loadOptions.pagReq.take || ITEMS_PER_PAGE, skip: loadOptions.pagReq.skip }
      : { take: ITEMS_PER_PAGE, skip: 0 };

    const filter = loadOptions?.filter || latestFilter.current;

    setLoading(true);

    try {
      const { data } = await gameService.retrieveAll({
        ...pagination,
        ...filter,
      });
      setData(data);
      setLoading(false);
      setHasBeenLoaded(true);

      latestFilter.current = filter;

      if (addQueryParams) {
        let searchToUpdate = createQueryForGameFilter(filter);

        navigate({
          pathname: location.pathname,
          search: `?${addPaginationToQuery(searchToUpdate, pagination)}`,
        });
      }

      if (typeof onSuccess === "function") {
        onSuccess();
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    addQueryParams &&
      load({
        filter: initialFilter,
        pagReq: initialPagination || { take: ITEMS_PER_PAGE, skip: 0 },
      })
        .then()
        .catch();
  }, [initialFilter, addQueryParams]);

  const refetch = useCallback(() => {
    // just use latest filter
    // load(latestFilter.current);
  }, []);

  return {
    data,
    refetch,
    load,
    loading,
    hasBeenLoaded,
  };
};
