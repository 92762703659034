import axios, { AxiosResponse } from "axios";
import { Platform } from "./platform.model";
import { endpoints } from "./endpoints";

class PlatformService {
  create(data: Pick<Platform, "name">): Promise<AxiosResponse<Platform>> {
    return axios.post(endpoints.create.insert(), {
      name: data.name,
    });
  }

  retrieveAll(): Promise<AxiosResponse<Platform[]>> {
    return axios.get(endpoints.retrieveAll.insert());
  }

  update(
    id: string,
    data: Pick<Platform, "name">
  ): Promise<AxiosResponse<Platform>> {
    return axios.put(endpoints.update.insert({ platformId: id }), {
      name: data.name,
    });
  }

  remove(id: string): Promise<AxiosResponse<void>> {
    return axios.delete(endpoints.remove.insert({ platformId: id }));
  }
}

export const platformService = new PlatformService();
