import { Box, Button, ChakraProps, FormControl, FormLabel, Tag, Text } from "@chakra-ui/react";
import { genName } from "../../utils/components/genName";
import { useDropzone } from "react-dropzone";
import React, { useRef } from "react";
import { LocalImage, ImageUpdater } from "./useImages";
import { generateGradient } from "./utils/generate-gradient";

const s3url = process.env.REACT_APP_ASSETS_URL;

const getImageUrl = (fileNameOrUrl: string) => {
  if (fileNameOrUrl.startsWith("http")) {
    return fileNameOrUrl;
  }

  if (fileNameOrUrl.startsWith("blob")) {
    return fileNameOrUrl;
  }

  return `${s3url}/${fileNameOrUrl}`;
};

type Props = {
  label?: string;

  ratio?: {
    x: number;
    y: number;
    size: number;
  };

  config: LocalImage;
  updater: ImageUpdater;
  showLink?: boolean;
  copyLinkInterceptor?: (link: string) => string;
};

export const Image = (props: Props) => {
  const colorThiefImgTagRef = useRef<null | HTMLImageElement>(null);

  const dropZone = useDropzone({
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/webp": [".webp"],
    },
    maxFiles: 1,
    onDrop: async (acceptedFiles) => {
      if (!colorThiefImgTagRef.current) {
        return;
      }

      if (!acceptedFiles.length) {
        return;
      }

      const acceptedFile = acceptedFiles[0];
      props.updater.updateFile(props.config.uniqId, acceptedFile);

      const objectURL = URL.createObjectURL(acceptedFile);
      props.updater.updateKey(props.config.uniqId, objectURL);

      const gradient = await generateGradient(objectURL, colorThiefImgTagRef.current);
      props.updater.updateGradient(props.config.uniqId, gradient);
    },
  });

  const ratioStyles: ChakraProps = props.ratio
    ? {
        width: props.ratio.size * props.ratio.x,
        height: props.ratio.size * props.ratio.y,
      }
    : {};

  const keyOrUrl: string | null = props.config.current.key;
  const gradient: string | null = props.config.current.gradient;

  return (
    <FormControl {...genName(Image)} w={"100%"} h={"100%"} display={"flex"} flexDirection={"column"} {...ratioStyles}>
      {props.label && <FormLabel flexShrink={0}>{props.label}</FormLabel>}
      <Box
        position={"relative"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        flexGrow={"2"}
        textAlign={"center"}
        minHeight={"80px"}
        borderTopWidth={!keyOrUrl ? "1px" : 0}
        borderLeftWidth={!keyOrUrl ? "1px" : 0}
        borderRightWidth={!keyOrUrl ? "1px" : 0}
        borderColor={"blackAlpha.200"}
        transition={"background-color 0.3s ease-in-out, border-color 0.3s ease-in-out"}
        cursor={"pointer"}
        _hover={{
          backgroundColor: "cyan.50",
          borderColor: "cyan.50",
        }}
        {...dropZone.getRootProps()}
      >
        <input {...dropZone.getInputProps()} />
        {!keyOrUrl && (
          <Box position={"absolute"} top={"50%"} left={"50%"} transform={"translate(-50%, -50%)"}>
            <Text fontWeight={"bold"} color={"blackAlpha.700"}>
              {dropZone.isDragActive ? "Drop Here" : "Drag Files or Click"}
            </Text>
          </Box>
        )}
        {keyOrUrl && <img style={{ width: "100%", height: "100%", objectFit: "cover" }} src={getImageUrl(keyOrUrl)} />}
      </Box>
      <Box
        flexShrink={0}
        background={gradient || "blackAlpha.200"}
        height={"30px"}
        display={"center"}
        justifyContent={"center"}
        alignItems={"center"}
        color={"blackAlpha.700"}
        fontSize={"12px"}
      >
        {!gradient && "No Image"}
      </Box>
      <Box as={"img"} position={"fixed"} top={"-100vh"} left={"-100vw"} ref={colorThiefImgTagRef} />
      {props.showLink && keyOrUrl && (
        <Box>
          {!getImageUrl(keyOrUrl).includes(s3url as string) && (
            <Tag mt={2} colorScheme={"red"}>
              Is not uploaded
            </Tag>
          )}
          {getImageUrl(keyOrUrl).includes(s3url as string) && (
            <Box>
              <Tag mt={2} colorScheme={"green"}>
                Uploaded
              </Tag>
              <Button
                size={"xs"}
                colorScheme={"linkedin"}
                onClick={async () => {
                  const text = props.copyLinkInterceptor && props.copyLinkInterceptor(getImageUrl(keyOrUrl));

                  await navigator.clipboard.writeText(text || getImageUrl(keyOrUrl));
                }}
              >
                Copy link
              </Button>
            </Box>
          )}
        </Box>
      )}
    </FormControl>
  );
};
