import { StoreName } from "../../api/stores/store-names.enum";

export const storeRegularExpressionsConfig: Record<StoreName, RegExp> = {
  [StoreName.Playstation]: /^https:\/\/store.playstation.com\/(product|concept)\/[^\/ ]+$/im,
  [StoreName.Xbox]: /^https:\/\/www.xbox.com\/games\/store\/[^\/ ]+\/([^\/ ]+\/[0-9]+|[^\/ ]+|[^\/ ]+\/[0-9]+\/[^\/ ]+)$/im,
  [StoreName.Microsoft]: /^https:\/\/www.microsoft.com\/p\/[^\/ ]+\/[^\/ ]+$/im,
  [StoreName.Steam]: /^https:\/\/store.steampowered.com\/app\/[^\/ ]+\/[^\/ ]+$/im,
  [StoreName.Nintendo]: /^https:\/\/www.nintendo.com\/store\/products\/[^\/ ]+$/im,
  [StoreName.ElectronicArts]: /^https:\/\/www.ea.com\/.*(\/buy\/pc)$/im,
  [StoreName.Ubisoft]: /^https:\/\/store.ubisoft.com\/.*(.html)$/im,
  [StoreName.EpicGames]: /^https:\/\/store.epicgames.com\/p\/[^\/ ]+$/im,
  [StoreName.BattleNet]: /^https:\/\/shop.battle.net\/.*/im,
};
