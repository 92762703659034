import { Game } from "../../../api/games/game.model";

export enum FilterCrossplayVerificationFilterValues {
  Verified = "Verified",
  NotVerified = "NotVerified",
  RequireVerification = "RequireVerification",
}

export type GameFilter = Partial<{
  search: string;
  publishStatus: Game["publishStatus"];
  crossplayVerification: keyof typeof FilterCrossplayVerificationFilterValues;
}>;
