import { v4 } from "uuid";
import { ImageInput, LocalImage } from "../useImages";

export const imageInputToLocal = (images: ImageInput[]): LocalImage[] => {
  return images.map((image) => ({
    uniqId: image.uniqId || v4(),
    current: {
      key: image.key || null,
      gradient: image.gradient || null,
      file: null,
    },
    original: {
      uniqId: image.uniqId,
      gradient: image.gradient,
      key: image.key,
    },
  }));
};
