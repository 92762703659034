export const util_arr_insertElem = <T extends object>(arr: T[], insertElem: T, position?: number): T[] => {
  if (typeof position === "undefined") {
    return [...arr, insertElem];
  }

  const resultedArr: T[] = [];

  arr.forEach((arrElem, index) => {
    if (index === position) {
      resultedArr.push(insertElem);
    }

    resultedArr.push(arrElem);

    if (position >= arr.length && index === arr.length - 1) {
      resultedArr.push(insertElem);
    }
  });

  return resultedArr;
};
