import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { GenericForm, GenericFormProps } from "../GenericForm/GenericForm";
import { useGenericFormRef } from "../GenericForm/useGenericFormRef";

type Props = {
  title: string;
  isOpen: boolean;
  onClose: () => void;

  actionText?: string;
  actionLoading: boolean;

  cancelText?: string;
} & GenericFormProps;

export const GenericFormModal = (props: Props) => {
  const genericFormRef = useGenericFormRef();

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>
          <GenericForm
            ref={genericFormRef}
            controls={props.controls}
            controlsValidation={props.controlsValidation}
            onSubmit={props.onSubmit}
            onControlChange={props.onControlChange}
          />
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button onClick={props.onClose}>{props.cancelText || "Cancel"}</Button>
            <Button
              variant={"outline"}
              colorScheme={"green"}
              isLoading={props.actionLoading}
              onClick={() => genericFormRef.current!.submit()}
            >
              {props.actionText || "Save"}
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
