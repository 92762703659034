import axios, { AxiosResponse } from "axios";
import { endpoints } from "./endpoints";

type UploadOptions = {
  convertToWebp?: true;
};

class UploaderService {
  upload(data: FormData, options: UploadOptions = {}): Promise<AxiosResponse<any>> {
    const queryParams: Record<string, any> = {};

    if (options.convertToWebp) {
      queryParams.convertToWebp = true;
    }

    return axios.post(endpoints.upload.insert(), data, {
      headers: { "Content-Type": "multipart/form-data" },
      params: queryParams,
    });
  }
}
export const uploaderService = new UploaderService();
