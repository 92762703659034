import { IconButtonProps } from "@chakra-ui/react";

export enum GenericFormV2ControlType {
    Text = "Text",
    Select = "Select",
    Date = "Date",
}

export interface GenericFormV2SelectOption {
    value: string;
    label: string;
}

interface BaseControl {
    type: keyof typeof GenericFormV2ControlType;
    label: string;
    name: string;
    disabled?: boolean;
    rightElement?: ExternalControl;
}

interface ExternalControl {
    name: string;
    render: React.ComponentType<ExternalControlProps>;
    props: ExternalControlProps;
}

export type ExternalControlProps = IconButtonProps | {}; // Extends this interface when more components will use

export interface TextControl extends BaseControl {
    type: GenericFormV2ControlType.Text;
}

export interface DateControl extends BaseControl {
    type: GenericFormV2ControlType.Date;
}

export interface SelectControl extends BaseControl {
    type: GenericFormV2ControlType.Select;
    options: GenericFormV2SelectOption[];
}

export type GenericFormV2AvailableControls = TextControl | SelectControl | DateControl;
