import { Alert, AlertIcon } from "@chakra-ui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { adminService } from "../../api/admin/admin.service";

export const Header = () => {
  const [isProd, setIsProd] = useState<boolean>(false);

  const loadStatus = async () => {
    try {
      const { data } = await adminService.isProd();
      setIsProd(data.status);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    loadStatus();
  }, []);

  return (
    <Alert status={isProd ? "error" : "info"}>
      <AlertIcon />
      {isProd || window.location.href.includes("admin.crosswatch.info") ? "Is Prod Environment!!!" : "Is Local Environment"}
    </Alert>
  );
};
