import {
  Box,
  Button,
  ChakraProps,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { PlusSquareIcon } from "@chakra-ui/icons";
import { useGenericFormV2 } from "../../../../components/GenericFormV2/use-generic-form-v2";
import { GenericFormV2ControlType } from "../../../../components/GenericFormV2/types";
import { gameService } from "../../../../api/games/game.service";
import { GenericFormV2 } from "../../../../components/GenericFormV2/GenericFormV2";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { convertMonthCalendarFormatIntoUnix } from "../../../../utils/date/convert-month-calendar-format-into-unix";

type Props = {
  onCreateSuccessfully: () => void;
  style?: ChakraProps;
};

export const GameCreateSection = (props: Props) => {
  const navigate = useNavigate();
  const toast = useToast();

  const [loading, setLoading] = useState<boolean>(false);

  const createGameModal = useDisclosure();

  const redirectAfterCreateRef = useRef(false);

  const createGameForm = useGenericFormV2({
    initialValues: {
      name: "",
      description: "",
      releaseDate: "",
    },
    controls: [
      {
        type: GenericFormV2ControlType.Text,
        name: "name",
        label: "Name",
      },
      {
        type: GenericFormV2ControlType.Text,
        name: "description",
        label: "Description",
      },
      {
        type: GenericFormV2ControlType.Date,
        name: "releaseDate",
        label: "Release Date",
      },
    ],
    validationScheme: yup.object().shape({
      name: yup.string().required(),
      description: yup.string(),
      releaseDate: yup.string(),
    }),
    onSubmit: async (values, formikHelpers) => {
      setLoading(true);

      try {
        const { data: game } = await gameService.create({
          name: values.name,
          releaseDate: convertMonthCalendarFormatIntoUnix(values.releaseDate),
          description: values.description,
        });

        props.onCreateSuccessfully();

        if (redirectAfterCreateRef.current) {
          navigate(`/games/${game._id}`);
          return;
        }

        createGameModal.onClose();

        toast({
          title: "Game was successfully created",
          status: "success",
        });
      } catch (err) {
        toast({
          title: "Game was not created",
          status: "error",
        });
        console.log(err);
      }

      setLoading(false);
    },
  });

  useEffect(() => {
    if (createGameModal.isOpen) {
      return;
    }

    createGameForm.formik.resetForm({});
  }, [createGameModal.isOpen]);

  return (
    <Box>
      <Button
        {...props.style}
        colorScheme={"green"}
        size={"sm"}
        leftIcon={<PlusSquareIcon />}
        onClick={createGameModal.onOpen}
      >
        Create Game
      </Button>
      <Modal isOpen={createGameModal.isOpen} onClose={createGameModal.onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Game</ModalHeader>
          <ModalBody>
            <GenericFormV2 controls={createGameForm.controls} formik={createGameForm.formik} />
          </ModalBody>
          <ModalFooter>
            <HStack spacing={2}>
              <Button onClick={createGameModal.onClose}>Cancel</Button>
              <Button
                variant={"outline"}
                colorScheme={"green"}
                isLoading={loading}
                onClick={createGameForm.formik.submitForm}
              >
                Create
              </Button>
              <Button
                variant={"outline"}
                colorScheme={"green"}
                isLoading={loading}
                onClick={() => {
                  redirectAfterCreateRef.current = true;
                  createGameForm.formik.submitForm();
                }}
              >
                Create and Open
              </Button>
            </HStack>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
