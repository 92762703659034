import { FormControl, FormErrorMessage, FormLabel, HStack, Input, Select, VStack } from "@chakra-ui/react";
import React from "react";
import { useFormik } from "formik";
import { GenericFormV2AvailableControls, GenericFormV2ControlType } from "./types";
import { convertUnixIntoMonthCalendarFormat } from "../../utils/date/convert-unix-into-month-calendar-format";

export type GenericFormV2Props = {
  controls: Array<GenericFormV2AvailableControls>;
  formik: ReturnType<typeof useFormik<any>>;
};

export const GenericFormV2 = (props: GenericFormV2Props) => {
  return (
    <VStack spacing={4}>
      {props.controls.map((control) => {
        const RightElement = control.rightElement?.render;

        return (
          <FormControl
            key={control.name}
            isInvalid={!!props.formik.errors[control.name] && !!props.formik.touched[control.name]}
          >
            <FormLabel>{control.label}</FormLabel>
            {control.type === GenericFormV2ControlType.Text && (
              <HStack>
                <>
                  <Input
                    name={control.name}
                    value={props.formik.values[control.name]}
                    onChange={props.formik.handleChange}
                    onBlur={props.formik.handleBlur}
                    disabled={control.disabled}
                  />
                  {RightElement && (
                    <RightElement aria-label="input external control" {...control.rightElement?.props} />
                  )}
                </>
              </HStack>
            )}
            {control.type === GenericFormV2ControlType.Date && (
              <HStack>
                <>
                  <Input
                    type={"date"}
                    name={control.name}
                    value={convertUnixIntoMonthCalendarFormat(props.formik.values[control.name])}
                    onChange={props.formik.handleChange}
                    onBlur={props.formik.handleBlur}
                    disabled={control.disabled}
                  />
                  {RightElement && (
                    <RightElement aria-label="input external control" {...control.rightElement?.props} />
                  )}
                </>
              </HStack>
            )}
            {control.type === GenericFormV2ControlType.Select && (
              <Select
                name={control.name}
                value={props.formik.values[control.name]}
                onChange={(e) => {
                  const value = e.target.value;

                  props.formik.setFieldValue(control.name, value);
                }}
                onBlur={props.formik.handleBlur}
                disabled={control.disabled}
              >
                {control.options.map((option) => {
                  return (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  );
                })}
              </Select>
            )}
            {props.formik.errors[control.name] && (
              <FormErrorMessage>{props.formik.errors[control.name] as string}</FormErrorMessage>
            )}
          </FormControl>
        );
      })}
    </VStack>
  );
};
