import { Box, Button, Flex, FormControl, FormLabel, Input, Textarea, useToast, VStack } from "@chakra-ui/react";
import { Image } from "../../components/Image/Image";
import { useImages } from "../../components/Image/useImages";
import { useLocation } from "react-router";
import React, { useEffect, useRef, useState } from "react";
import { ArticleService } from "../../api/articles/article.service";
import { Article, ArticleAttachmentType, ArticlePublishStatus } from "../../api/articles/article.model";
import { v4 } from "uuid";
import { useParams } from "react-router-dom";
import { ArticleManageAttachments } from "./components/ArticleManageAttachments/ArticleManageAttachments";
import { RepeatIcon, SmallCloseIcon } from "@chakra-ui/icons";
import { usePreview } from "../../hooks/usePreview/usePreview";
import { GameCompletionService } from "../../services/game-completion.service";
import { gameService } from "../../api/games/game.service";
import { Game } from "../../api/games/game.model";

function calculateReadTime(text: string): number {
  // Function to remove HTML tags
  const removeHtmlTags = (html: string): string => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = html;
    return tempDiv.textContent || tempDiv.innerText || "";
  };

  // Clean the text by removing HTML tags
  const cleanText = removeHtmlTags(text);

  // Calculate the number of words
  const wordCount = cleanText.split(/\s+/).filter((word) => word.length > 0).length;

  // Calculate reading time (in minutes)
  const readTime = Math.ceil(wordCount / 200); // average reading speed

  return readTime;
}

export const ArticleManagePage = () => {
  const toast = useToast();
  const params = useParams();

  const preview = usePreview({
    entity: "article",
  });

  const [articleLoading, setArticleLoading] = useState<boolean>(true);
  const [articleIdIsNotValid, setArticleIdIsNotValid] = useState<boolean>(false);
  const [article, setArticle] = useState<Article | null>(null);
  const [articleUpdateLoading, setArticleUpdateLoading] = useState<boolean>(false);
  const [changesFromHistoryAreApplied, setChangesFromHistoryAreApplied] = useState<boolean>(false);
  const [publishLoading, setPublishLoading] = useState<boolean>(false);
  const [disablePage, setDisablePage] = useState<boolean>(false);
  const sessionIdRef = useRef<string>("");
  const timeoutReloadRef = useRef<any>();
  const previewEntityRef = useRef<Article | null>(null);
  const gameEntityRef = useRef<Game | null>(null);

  const titleInputRef = useRef<HTMLInputElement>({} as HTMLInputElement);
  const shortDescriptionRef = useRef<HTMLTextAreaElement>({} as HTMLTextAreaElement);
  const rawHTMLRef = useRef<HTMLTextAreaElement>({} as HTMLTextAreaElement);

  const articleAttachmentsControllerRef = useRef<ArticleManageAttachments["FRefController"]>(null);

  const image = useImages();
  const location = useLocation();

  useEffect(() => {
    return () => {
      clearTimeout(timeoutReloadRef.current);
    };
  }, []);

  useEffect(() => {
    const articleId = params.articleId;

    if (!articleId) {
      setArticleIdIsNotValid(true);
      return;
    }

    (async () => {
      try {
        const { data: articleData } = await ArticleService.retrieveById(articleId);
        const { data: gameData } = await gameService.retrieveById(articleData.belongsTo.id);

        setArticle(articleData);
        gameEntityRef.current = gameData;
        previewEntityRef.current = articleData;
        preview.updateEntity({
          article: previewEntityRef.current,
          game: gameData,
        });
        titleInputRef.current.value = articleData.title;
        shortDescriptionRef.current.value = articleData.shortDescription;
        rawHTMLRef.current.value = articleData.rawHtml;
        image.addOriginal({
          uniqId: articleData.cover._id,
          key: articleData.cover.key,
          gradient: articleData.cover.gradient,
        });
      } catch (err) {
        setArticleIdIsNotValid(true);
      } finally {
        if (!previewEntityRef.current) {
          return;
        }

        setArticleLoading(false);

        const sessionId = new URLSearchParams(location.search).get("sessionId");

        if (sessionId) {
          sessionIdRef.current = sessionId;

          const data = JSON.parse(sessionStorage.getItem(sessionId) as string) as Partial<{
            title: string;
            shortDescription: string;
            rawHTML: string;
          }>;

          if (data?.title?.trim().length) {
            setChangesFromHistoryAreApplied(true);
            titleInputRef.current.value = data.title;
            previewEntityRef.current = {
              ...previewEntityRef.current,
              title: data.title,
            };
            preview.updateEntity(previewEntityRef.current);
          }

          if (data?.shortDescription?.trim().length) {
            setChangesFromHistoryAreApplied(true);
            shortDescriptionRef.current.value = data.shortDescription;
            previewEntityRef.current = {
              ...previewEntityRef.current,
              shortDescription: data.shortDescription,
            };
            preview.updateEntity(previewEntityRef.current);
          }

          if (data?.rawHTML?.trim().length) {
            setChangesFromHistoryAreApplied(true);
            rawHTMLRef.current.value = data.rawHTML;
            previewEntityRef.current = {
              ...previewEntityRef.current,
              rawHtml: data.rawHTML,
            };
            preview.updateEntity(previewEntityRef.current);
          }
        } else {
          sessionIdRef.current = v4();

          const url = new URL(window.location.href);
          url.searchParams.set("sessionId", sessionIdRef.current);

          window.history.replaceState(null, "", url.toString());
        }
      }
    })();
  }, []);

  const updateArticle = async () => {
    if (!articleAttachmentsControllerRef.current) {
      return;
    }

    if (!article) {
      return;
    }

    if (image.images[0].current?.key == null || image.images[0].current?.gradient === null) {
      return;
    }

    if (titleInputRef.current.value.trim().length === 0) {
      return;
    }

    if (shortDescriptionRef.current.value.trim().length === 0) {
      return;
    }

    if (rawHTMLRef.current.value.trim().length === 0) {
      return;
    }

    setArticleLoading(true);

    const mainImageOutputs = await image.getImageResults({ srcResultType: "final" });
    const attachmentsOutputs = await articleAttachmentsControllerRef.current.getImages();

    try {
      setArticleUpdateLoading(true);
      await ArticleService.updateById(article._id, {
        title: titleInputRef.current.value,
        shortDescription: shortDescriptionRef.current.value,
        rawHtml: rawHTMLRef.current.value,
        attachments: attachmentsOutputs.map((attachmentOutput) => {
          const original = article.attachments.find((element) => element.key === attachmentOutput.key);

          if (original) {
            return {
              ...original,
            };
          }

          return {
            type: ArticleAttachmentType.Image,
            key: attachmentOutput.key,
            gradient: attachmentOutput.gradient,
          };
        }),
        cover: {
          _id: mainImageOutputs[0]._id,
          key: mainImageOutputs[0].key,
          gradient: mainImageOutputs[0].gradient,
        },
        readTime: calculateReadTime(rawHTMLRef.current.value),
      });

      sessionStorage.removeItem(sessionIdRef.current);
      setChangesFromHistoryAreApplied(false);
      toast({
        title: "Article was updated successful",
        status: "success",
      });
    } catch (err) {
      toast({
        title: "Article was not updated",
        status: "error",
      });
    } finally {
      setArticleUpdateLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (!article || !previewEntityRef.current) {
        return;
      }

      const [result] = await image.getImageResults({ srcResultType: "dataUrl" });

      previewEntityRef.current = {
        ...previewEntityRef.current,
        cover: {
          _id: result._id || Date.now().toString(),
          key: result.key,
          gradient: result.gradient,
        },
      };
      updatePreviewEntity(previewEntityRef.current);
    })();
  }, [image.images]);

  const putInSessionStore = (key: "title" | "shortDescription" | "rawHTML", value: string) => {
    setChangesFromHistoryAreApplied(true);
    const existed = sessionStorage.getItem(sessionIdRef.current);

    if (!existed) {
      sessionStorage.setItem(sessionIdRef.current, JSON.stringify({ [key]: value }));
      return;
    }

    const existedDataObj = JSON.parse(existed) as object;

    sessionStorage.setItem(
      sessionIdRef.current,
      JSON.stringify({
        ...existedDataObj,
        [key]: value,
      })
    );
  };

  const updatePreviewEntity = (
    partialEntity: Partial<Pick<Article, "title" | "rawHtml" | "shortDescription" | "cover">>
  ) => {
    if (previewEntityRef.current === null) {
      return;
    }

    previewEntityRef.current = {
      ...previewEntityRef.current,
      ...partialEntity,
    };

    preview.updateEntity({
      article: previewEntityRef.current,
      game: gameEntityRef.current,
    });
  };

  const changePublishStatus = async (nextPublishState: ArticlePublishStatus) => {
    if (!article) {
      return;
    }

    try {
      setPublishLoading(true);
      await ArticleService.updatePublishStatus(article._id, {
        publishStatus: nextPublishState,
      });
      toast({
        title: "Publish Status was updated",
        status: "success",
      });
      toast({
        title: "Page will be reloaded in 5 seconds",
        status: "info",
      });

      timeoutReloadRef.current = setTimeout(() => {
        window.location.reload();
      }, 5000);
      setDisablePage(true);
    } catch (err) {
      toast({
        title: "Publish Status was not updated",
        status: "error",
      });
    } finally {
      setPublishLoading(false);
    }
  };

  return (
    <Box
      style={
        disablePage
          ? { opacity: 0.5, transition: "opacity 300ms", pointerEvents: "none" }
          : { transition: "opacity 300ms" }
      }
    >
      <Box>Update Article Page</Box>
      <Button
        size={"sm"}
        colorScheme={"teal"}
        onClick={preview.start}
        isLoading={preview.connectionStatus.Connecting}
        disabled={!preview.connectionStatus.Initial}
      >
        {preview.connectionStatus.Initial && "Open preview"}
        {preview.connectionStatus.Connecting && "Opening"}
        {preview.connectionStatus.Connected && "Preview is Open"}
        {preview.connectionStatus.Disconnected && "Preview has been disconnected"}
      </Button>
      {preview.connectionStatus.Disconnected && (
        <Button size={"sm"} onClick={preview.start}>
          <RepeatIcon />
        </Button>
      )}
      {preview.connectionStatus.Connected && (
        <Button size={"sm"} onClick={preview.disconnect} leftIcon={<SmallCloseIcon />}>
          Disconnect
        </Button>
      )}
      {changesFromHistoryAreApplied && (
        <Box>
          <Box>
            Please Pay attention that changes from history are applied, if you want to clear it click on button below
          </Box>
          <Button
            onClick={() => {
              sessionStorage.removeItem(sessionIdRef.current);
              window.location.reload();
            }}
          >
            Clear History
          </Button>
        </Box>
      )}
      {articleIdIsNotValid && (
        <Box>!!!Game ID in URL params is not valid or not exist, please back to game page and try again</Box>
      )}
      {articleLoading && <Box>!!!Please wait we are loading required data</Box>}
      {article === null && <Box>!!!No Game provided</Box>}
      <Button
        size={"sm"}
        variant={"outline"}
        mb={1}
        colorScheme={"green"}
        onClick={() =>
          changePublishStatus(
            article?.publishStatus === ArticlePublishStatus.Published
              ? ArticlePublishStatus.Draft
              : ArticlePublishStatus.Published
          )
        }
        isLoading={publishLoading}
      >
        {article?.publishStatus === ArticlePublishStatus.Published ? "Back To Draft" : "Publish Article"}
      </Button>
      <VStack alignItems={"start"}>
        <FormControl isInvalid={false}>
          <FormLabel>Title</FormLabel>
          <Input
            ref={titleInputRef as any}
            type="text"
            onChange={(e) => {
              putInSessionStore("title", e.target.value);
              updatePreviewEntity({ title: e.target.value });
            }}
          />
        </FormControl>
        {image.images[0] && (
          <Image ratio={{ size: 20, x: 16, y: 9 }} config={image.images[0]} updater={image.updater} />
        )}
        <FormControl isInvalid={false}>
          <FormLabel>Short Description</FormLabel>
          <Textarea
            ref={shortDescriptionRef as any}
            rows={6}
            onChange={(e) => {
              putInSessionStore("shortDescription", e.target.value);
              updatePreviewEntity({ shortDescription: e.target.value });
            }}
          />
        </FormControl>
        <FormControl isInvalid={false}>
          <FormLabel>Raw HTML</FormLabel>
          <Textarea
            ref={rawHTMLRef as any}
            rows={15}
            onChange={(e) => {
              putInSessionStore("rawHTML", e.target.value);
              updatePreviewEntity({ rawHtml: e.target.value });
            }}
          />
        </FormControl>
        <Flex>
          {article && (
            <ArticleManageAttachments ref={articleAttachmentsControllerRef} attachments={article.attachments} />
          )}
        </Flex>
        <Button variant={"outline"} colorScheme={"linkedin"} isLoading={articleUpdateLoading} onClick={updateArticle}>
          Update
        </Button>
      </VStack>
    </Box>
  );
};
