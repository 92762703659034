import { useEffect, useState } from "react";
import { ArticleService } from "../api/articles/article.service";
import { PagRes } from "../api/shared/pagRes.model";
import { Article } from "../api/articles/article.model";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";

type PaginationData = {
  take?: number;
  skip: number;
};

const ITEMS_PER_PAGE = 20;

export const useArticles = (options?: { belongsTo: string }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const [data, setData] = useState<PagRes<Article>>({
    take: ITEMS_PER_PAGE,
    skip: 0,
    entities: [],
    count: 0,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const [hasBeenLoaded, setHasBeenLoaded] = useState<boolean>(false);

  const load = async (pagReq?: PaginationData) => {
    setLoading(true);

    try {
      const { data } = await ArticleService.retrieveAll({
        take: pagReq?.take || ITEMS_PER_PAGE,
        skip: pagReq?.skip || 0,
        ...(options?.belongsTo ? { belongsTo: options.belongsTo } : {}),
      });

      setData(data);
      setLoading(false);
      setHasBeenLoaded(true);

      navigate(
        {
          pathname: location.pathname,
          search: `?take=${data.take}&skip=${data.skip}`,
        },
        { replace: true }
      );
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    load().then().catch();
  }, []);

  const refetch = () => {
    load();
  };

  return {
    data,
    refetch,
    load,

    loading,
    hasBeenLoaded,
  };
};
