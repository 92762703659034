import { ForwardedRef, forwardRef, useImperativeHandle, useState } from "react";
import {
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  useDisclosure,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import { PlayMode } from "../../../../api/play-modes/play-mode.model";

type RefController = {
  open: (playMode: PlayMode) => void;
  switchLoading: (next: boolean) => void;
  clear: () => void;
  close: () => void;
};

type Props = {
  onRemove: (playMode: PlayMode) => void;
};

export const RemovePlayModeModal = forwardRef((props: Props, ref: ForwardedRef<RefController>) => {
  const modalState = useDisclosure();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [playMode, setPlayMode] = useState<PlayMode | null>(null);

  useImperativeHandle(ref, () => ({
    open: (data) => {
      setPlayMode(data);
      modalState.onOpen();
    },
    close: () => {
      modalState.onClose();
    },
    clear,
    switchLoading: (next) => setIsLoading(next),
  }));

  const clear = () => {
    setIsLoading(false);
    setPlayMode(null);
  };

  const handleClose = () => {
    modalState.onClose();
    clear();
  };

  const handleRemove = () => {
    if (!playMode) {
      return;
    }

    props.onRemove(playMode);
  };

  return (
    <Modal isOpen={modalState.isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Play Mode</ModalHeader>
        <ModalBody>
          <WarningIcon color="yellow.400" /> Are you sure you want to remove <Tag>{playMode?.name}</Tag> tag?
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button onClick={handleClose}>Close</Button>
            <Button
              variant={"outline"}
              colorScheme={"green"}
              onClick={handleRemove}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Confirm
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export type RemovePlayModeModal = {
  Props: Props;
  RefController: RefController;
};
