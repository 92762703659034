import { useEffect, useState } from "react";
import { Social } from "../api/socials/social.model";
import { SocialService } from "../api/socials/genre.service";

export const useSocials = () => {
  const [list, setList] = useState<Social[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [hasBeenLoaded, setHasBeenLoaded] = useState<boolean>(false);

  const load = async () => {
    setLoading(true);

    try {
      const { data } = await SocialService.retrieveAll();
      setList(data);
      setLoading(false);
      setHasBeenLoaded(true);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    load().then().catch();
  }, []);

  const refetch = () => {
    load();
  };

  return {
    list,
    refetch,

    loading,
    hasBeenLoaded,
  };
};
